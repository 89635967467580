<template>
	<div class="bg-white p-6 md:flex md:flex-col">
		<h1 class="text-2xl tracking-tighter text-gray-500">Claim History</h1>
		<div class="mt-4 grid md:grid-cols-3 items-stretch mr-5">
			<div class="hidden md:block bg-gray-100 font-medium rounded-l-xl">
				<p class="px-4 py-3">Claimed Miles</p>
			</div>
			<div class="hidden md:block bg-gray-100 font-medium text-center">
				<p class="px-4 py-3">Drips Claimed</p>
			</div>
			<div class="hidden md:block bg-gray-100 font-medium text-right rounded-r-xl">
				<p class="px-4 py-3">Date</p>
			</div>
		</div>

		<div class="md:overflow-y-auto md:py-1 md:pr-2 md:my-1">
			<template v-for="item in groupedHistory">
				<div
					class="flex space-x-2 mt-3 items-center even:bg-gray-50 py-2 px-4 md:py-0 md:px-0 md:mt-0 md:grid md:grid-cols-3 rounded-xl"
				>
					<div class="leading-0 md:px-4 md:py-4">
						<p class="-grid grid-cols-[1fr,2fr] gap-x-4">
							<!-- <span class="">Claimed</span> -->
							<span
								>{{ $format(currencyToDecimal(item.amount, { showDecimals: false })) }}
								{{ $token }}</span
							>
						</p>
					</div>
					<div class="hidden md:block px-4 py-2 text-center">
						{{ $format(item.claims.length) }}
						<pluralize :amount="item.claims.length" singular="Drip" plural="Drips" />
					</div>
					<div class="text-sm text-gray-400 italic md:not-italic md:text-base md:text-right md:py-2 md:px-4">
						<span class="md:hidden">on </span>
						<span class="">
							{{ item.date }}
						</span>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { DripClaim } from '@/types/Drip'
import { currencyToDecimal } from '@/util/currencyFormat'
import { DateTime } from 'ts-luxon'
import { computed } from 'vue'
const props = defineProps<{
	history: DripClaim[]
}>()

interface HistoryItem {
	amount: number
	date: string
	claims: DripClaim[]
}

interface AggregatedHistory {
	[d: string]: HistoryItem
}

const groupedHistory = computed(() => {
	return props.history.map((item) => {
		const date = DateTime.fromISO(item.claim_date)
		const dateString = date.toLocaleString({
			...DateTime.DATE_MED,
		})

		return {
			...item,
			date: dateString,
		}
	})
})
</script>
