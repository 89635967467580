<template>
	<modal>
		<template #default="handlers">
			<div class="bg-white rounded-xl overflow-hidden drop-shadow-md w-full">
				<div class="p-4 sm:p-6">
					<div class="max-w-screen-lg mx-auto">
						<h1 class="text-2xl font-semibold border-b-2 border-gray-300 pb-1">Add Blokpax Miles</h1>
					</div>

					<section class="max-w-screen-lg mx-auto pt-6 md:grid md:grid-cols-[1fr,2fr] md:gap-6">
						<div>
							<img src="/image/blokpax-miles-card.webp" class="w-full rounded-lg" />

							<div class="">
								<h2 class="text-sm font-bold mt-3">
									Get Access to Amazing Benefits and Rewards with Blokpax Miles!
								</h2>
								<p class="mt-2 text-sm">
									Use Blokpax Miles throughout the Blokpax Marketplace to score exclusive rewards and
									add real, physical, graded cards to your collection. Blokpax Miles can be used to
									purchase cards, access exclusive content, and more!
								</p>
							</div>
						</div>

						<div v-if="options.length > 0" class="">
							<h1 class="text-lg my-2 font-medium text-gray-800">Select a bundle</h1>
							<div class="space-y-2">
								<article
									v-for="(opt, i) in options"
									:key="i"
									class="flex justify-start items-stretch bg-gray-50 cursor-pointer hover:bg-gray-100"
									@click="selectedPrice = opt.price"
								>
									<div
										class="flex-grow-0 flex-shrink-0 border-t border-l border-b w-3 rounded-l-lg"
										:class="[
											opt.price == selectedPrice
												? 'bg-sky-600 border-sky-600'
												: 'bg-transparent border-gray-200',
										]"
									></div>
									<div
										class="relative flex-grow flex-shrink-0 p-3 border-t border-b border-r border-gray-200 rounded-r-lg"
										:class="[
											opt.price == selectedPrice
												? 'bg-sky-300/20 border-sky-600'
												: 'bg-transparent border-gray-200',
										]"
									>
										<p class="text-lg font-medium tracking-tighter">
											{{ $format(opt.amount) }}
											<span v-if="opt.bonus > 0">(+{{ $format(opt.bonus) }} Bonus)</span>
											{{ $token }}
										</p>
										<p class="text-2xl font-bold tracking-tighter mt-1">
											<span>${{ $format(opt.price) }}</span>
											<span class="font-normal text-sm text-gray-500 tracking-tight">
												for {{ $format(opt.amount + opt.bonus) }} total {{ $token }}
											</span>
										</p>
										<div
											v-if="opt.mostPopular || opt.bestValue"
											class="z-10 absolute shadow-md text-sm lg:text-base -top-2 md:top-0 -right-4 bg-red-600 text-white rounded-lg py-1 px-3 lg:px-4 rotate-6"
										>
											<span v-if="opt.mostPopular">Most Popular!</span>
											<span v-if="opt.bestValue">Best Value!</span>
										</div>
									</div>
								</article>
							</div>
						</div>
						<div v-else>
							<p class="text-gray-500">Loading packages...</p>
						</div>
					</section>
				</div>

				<div class="mt-4 bg-gray-200 px-4 py-4 text-right space-x-4 border-t-2 border-gray-300">
					<button class="text-gray-500" @click="handlers.close">Cancel</button>
					<button class="btn-primary" @click="buy">Next</button>
				</div>
			</div>
		</template>
	</modal>
</template>
<script lang="ts" setup>
import { computed, reactive, ref, onMounted } from 'vue'

import { useMarketStore } from '@/stores/MarketStore'

const marketStore = useMarketStore()

const options = ref<
	Array<{
		price: number
		amount: number
		bonus: number | null
		mostPopular: boolean
		bestValue: boolean
	}>
>([])

const selectedPrice = ref<number>(249)

const props = withDefaults(
	defineProps<{
		redirect: String | null
	}>(),
	{
		redirect: () => null,
	}
)

const state = reactive({
	optSelected: null,
	amount: 0,
	customAmount: null,
})

onMounted(async () => {
	options.value = await marketStore.getMilesPackages()
})

function selectAmount(opt: number) {
	state.optSelected = opt
}

function buttonClasses(opt: number) {
	if (state.optSelected == opt) {
		return 'bg-sky-700 text-white border-sky-700'
	}

	return 'bg-gray-100 hover:bg-sky-600 hover:text-white hover:border-sky-700/80'
}

function subTextClasses(opt) {
	if (state.optSelected == opt) {
		return 'text-gray-50'
	}

	return 'text-gray-600'
}

const amountValid = computed(() => {
	return state.optSelected !== null
})

function buy() {
	executePurchase(selectedPrice.value)
}

async function executePurchase(amount: number) {
	const response = await marketStore.initiateStoreCreditPurchase(amount, props.redirect)

	if (response.success) {
		window.top.location = response.redirect
		return
	}

	alert(response.message)
}
</script>
