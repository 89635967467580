<template>
	<div>
		<header class="bpmp-layout__header">
			<market-account-nav />
		</header>
		<div class="bpmp-layout__content">
			<slot></slot>
		</div>
	</div>
</template>
<script lang="ts" setup>
import MarketAccountNav from '@/components/MarketAccountNav.vue'
</script>
