<template>
	<div>
		<div
			v-if="state.txInProgress"
			class="my-6 grid grid-cols-[auto,1fr] gap-4 items-start p-4 bg-sky-200 text-slate-950 rounded-lg"
		>
			<div class="text-4xl animate-spin text-blue-600">
				<i class="far fa-spinner-third"></i>
			</div>
			<div>
				<div class="leading-10">Waiting on blockchain...</div>
			</div>
		</div>
		<div
			v-else-if="state.txComplete"
			class="my-6 grid grid-cols-[auto,1fr] gap-4 items-start p-4 bg-emerald-200 text-slate-950 rounded-lg"
		>
			<div class="text-4xl text-emerald-600">
				<i class="far fa-circle-check"></i>
			</div>
			<div class="space-y-4">
				<div class="leading-10 font-bold">Transaction complete!</div>
				<div>Deposited assets may take up to five minutes to appear in your account.</div>
				<div>
					<a
						:href="`https://etherscan.io/tx/${state.tx.transactionHash}`"
						class="underline hover:no-underline"
						target="_blank"
						><i class="far fa-external-link"></i> View your transaction</a
					>
				</div>
			</div>
		</div>
		<div
			v-else-if="state.txError"
			class="my-6 grid grid-cols-[auto,1fr] gap-4 items-start p-4 bg-red-200 text-slate-950 rounded-lg"
		>
			<div class="text-4xl text-red-600">
				<i class="far fa-circle-exclamation"></i>
			</div>
			<div>
				<div class="leading-10">Something went wrong!</div>

				<div>
					Try again in a few minutes. If you're still having trouble, reach out to Blokpax support at
					<a href="mailto:help@blokpax.com" class="underline hover:no-underline">help@blokpax.com</a>.
				</div>
			</div>
		</div>

		<wallet-connected>
			<template #default>
				<div class="mt-12">
					<button
						class="w-full text-xl font-medium bg-slate-400 text-slate-600 enabled:bg-blue-600 enabled:text-slate-50 enabled:hover:saturate-150 p-4 text-center rounded-lg transition-all"
						@click="metamask.connect"
					>
						Connect Wallet
					</button>
				</div>
			</template>

			<template #connected>
				<div class="divide-y divide-gray-300/50">
					<div class="space-y-6 text-base leading-7 text-gray-600">
						<div>
							<p class="font-semibold">Your wallet</p>
							<p class="font-mono text-sm">
								<wallet-address :address="metamask.state.wallet" :full="true" />
							</p>
						</div>

						<div v-if="!userLinkedWallet" class="bg-red-100 rounded-lg text-red-900 p-4">
							<span class="text-2xl font-bold tracking-tight">Warning!</span>
							<p class="leading-snug">
								The wallet you have selected is not linked to your Blokpax account. Any BPX -> Miles
								swaps will not be processed from this wallet, until you link it to your account.
							</p>
						</div>

						<div class="flex flex-col items-center">
							<div class="w-full rounded-lg p-4 shadow-md">
								<p class="text-lg font-semibold">Swap</p>
								<div class="flex rounded-lg border border-gray-400 px-3 py-1 items-center">
									<input
										placeholder="0"
										class="flex-grow bg-transparent outline-none ring-0 border-0 focus:ring-0 disabled:border-slate-400 disabled:text-slate-400 disabled:opacity-50 disabled:cursor-not-allowed"
										type="tel"
										v-model="state.currentExchangeAmt"
										:disabled="state.txInProgress"
									/>
									<span>{{ state.swapStateDeposit ? 'BPX' : 'Miles' }}</span>
								</div>
								<div class="mt-2 flex items-center justify-end space-x-2">
									<div class="text-sm text-gray-400">
										<div class="flex">
											<span class="font-medium mr-1 flex flex-col">
												<span>Available:</span>
												<span v-if="!state.swapStateDeposit">Withdrawable:</span>
											</span>
											<div v-if="state.swapStateDeposit">
												<span>{{ $format(toFloat(state.balanceOnChain)) }}</span>
											</div>
											<div v-else class="flex flex-col">
												<span>{{
													$format(humanReadablePrice(balanceInMarket.toString()))
												}}</span>
												<span>{{ $format(toFloat(balanceInMarketWithdrawable)) }}</span>
											</div>
										</div>
									</div>

									<button
										@click="maxDeposit"
										class="rounded-xl border border-blue-400 px-2 py-1 text-sm text-blue-400 enabled:hover:bg-blue-400 hover:text-white disabled:border-slate-400 disabled:text-slate-400 disabled:opacity-50 disabled:cursor-not-allowed"
										:disabled="state.txInProgress"
									>
										Max
									</button>
								</div>
							</div>

							<button
								@click="switchSwapState()"
								class="group relative z-10 -my-3 h-12 w-12 rounded-full border border-blue-400 bg-white p-3 text-blue-400 shadow-md enabled:hover:bg-blue-400 disabled:border-slate-300 disabled:cursor-not-allowed"
								:disabled="state.txInProgress"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512"
									class="fill-blue-400 group-hover:fill-white"
									:class="[state.txInProgress ? 'fill-gray-400 group-hover:fill-gray-400' : '']"
								>
									<path
										d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z"
									/>
								</svg>
							</button>

							<div class="w-full rounded-lg p-4 shadow-md">
								<p class="text-lg font-semibold">For</p>
								<div class="flex rounded-lg border border-gray-400 px-3 py-1 items-center">
									<input
										placeholder="0"
										type="tel"
										class="flex-grow bg-transparent outline-none ring-0 border-0 focus:ring-0 disabled:border-slate-400 disabled:text-slate-400 disabled:opacity-50 disabled:cursor-not-allowed"
										v-model="state.currentExchangeAmt"
										:disabled="state.txInProgress"
									/>
									<span>{{ state.swapStateDeposit ? 'Miles' : 'BPX' }}</span>
								</div>
								<div class="mt-2 flex items-center justify-end space-x-2">
									<div class="text-sm text-gray-400">
										<div class="flex">
											<span class="font-medium mr-1">Balance:</span>
											<div v-if="state.swapStateDeposit">
												<span>{{ $format(toFloat(balanceInMarket)) }}</span>
											</div>
											<div v-else>
												<span>{{ $format(toFloat(state.balanceOnChain)) }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="text-right">
							<button
								@click="executeSwap"
								:disabled="!validateExchangeInput || state.txInProgress"
								class="rounded-lg border border-blue-400 px-4 py-1 font-medium text-blue-400 enabled:hover:bg-blue-400 enabled:hover:text-white disabled:border-slate-400 disabled:text-slate-400 disabled:opacity-50 disabled:cursor-not-allowed"
							>
								Swap
							</button>
						</div>
					</div>
				</div>
			</template>
		</wallet-connected>
	</div>
</template>
<script lang="ts">
import { useNewWalletStore } from '@/stores/NewWalletStore'
import { wasNotDeclined } from '@/util/Errors'
import { useAuthStore } from '@/stores/AuthStore'

import { defineComponent, PropType, reactive, computed, onMounted, ref, ComputedRef } from 'vue'
import Bugsnag from '@bugsnag/js'
import sleep from '@/util/sleep'
import metamask from '@/util/metamask'
import Currency from '@/types/Currency'
import IERC20 from '@/abi/IERC20.json'
import { BPX_ADDRESS } from '@/util/escrow'
import { humanReadablePrice } from '@/util/currencyFormat'
import IGatewayModule from '@/abi/IGatewayModule.json'
import { currencyToDecimal } from '@/util/currencyFormat'

const ETH_SAFE_ADDRESS = import.meta.env.VITE_ETH_SAFE_ADDRESS
const ETH_SAFE_GATEWAY_ADDRESS = import.meta.env.VITE_ETH_SAFE_GATEWAY_ADDRESS

export default defineComponent({
	setup(props) {
		const state = reactive({
			currentExchangeAmt: 0,
			txInProgress: false,
			txComplete: false,
			txError: null,
			tx: null,
			balanceOnChain: BigInt(0),
			swapStateDeposit: true,
		})

		const newWalletStore = useNewWalletStore()
		const authStore = useAuthStore()

		const balanceInMarket: ComputedRef<bigint> = computed(() => {
			const val = newWalletStore.balances.reduce(function (bnBalance, bal) {
				if (bal.slug == 'bpx' || bal.slug == 'vbpx') {
					return bnBalance + BigInt(bal.available.toString())
				}

				return bnBalance
			}, BigInt(0))

			return val
		})

		function executeSwap() {
			if (state.swapStateDeposit) {
				executeDeposit()
			} else {
				executeWithdrawal()
			}
		}

		const validateExchangeInput = computed(() => {
			// validating numeric input
			if (state.currentExchangeAmt === null || isNaN(state.currentExchangeAmt)) {
				return false
			}

			if (state.swapStateDeposit) {
				return (
					cleanInput(state.currentExchangeAmt) > 0 &&
					cleanInput(state.currentExchangeAmt) <= toFloat(state.balanceOnChain)
				)
			} else {
				return (
					cleanInput(state.currentExchangeAmt) > 0 &&
					cleanInput(state.currentExchangeAmt) <= toFloat(balanceInMarketWithdrawable.value)
				)
			}
		})

		const balanceInMarketWithdrawable: ComputedRef<bigint> = computed(() => {
			const bal = newWalletStore.balances.find((b) => b.slug == 'bpx')

			if (!bal) {
				return BigInt(0)
			}

			return BigInt(bal.available.toString())
		})

		const userLinkedWallet = computed(() => {
			const wallet = metamask.state.wallet
			const linkedWallets = authStore.user.metadata?.wallets ?? authStore.user.wallets

			const isUsingLinkedWallet = linkedWallets.reduce(function (key, val) {
				if (wallet.toLowerCase() === val.toLowerCase()) {
					return true
				}
			}, false)

			return isUsingLinkedWallet
		})

		async function executeWithdrawal() {
			state.txError = null
			state.txInProgress = true
			state.txComplete = false

			if (metamask.state.chain !== import.meta.env.VITE_BPX_CHAIN) {
				const res = await metamask.requestChain(import.meta.env.VITE_BPX_CHAIN)
				if (!res) {
					state.txInProgress = false
					return
				}
			}

			const wallet = metamask.state.wallet

			const marketplace = await metamask.loadContract(IGatewayModule, ETH_SAFE_GATEWAY_ADDRESS)

			const asset = `1:${import.meta.env.VITE_BPX_ADDRESS}:0`.toLowerCase()
			const amount = humanReadableToBpx(cleanInput(state.currentExchangeAmt))

			let authedTx
			try {
				authedTx = await newWalletStore.withdraw(asset, amount.toString(), wallet)
			} catch (e) {
				console.error({ authedTx: e })
				state.txInProgress = false
				state.txError = e.toString()
				return
			}

			const execute = await marketplace.methods.execute(
				authedTx.safe,
				authedTx.destination,
				authedTx.transaction,
				false,
				authedTx.expiration,
				authedTx.identifier,
				authedTx.counter,
				authedTx.salt,
				authedTx.signature
			)

			let gasEstimate
			try {
				gasEstimate = await execute.estimateGas({
					from: metamask.state.wallet,
					gas: 500_000,
				})
			} catch (e) {
				console.error({ estimateGas: e, metamask: metamask.state })
				state.txInProgress = false
				state.txError = e.toString()
				return
			}

			gasEstimate = Math.ceil(gasEstimate * 1.3)

			const wait = sleep(1)

			const receipt = await execute
				.send({
					from: metamask.state.wallet,
					gas: gasEstimate,
					maxPriorityFeePerGas: null,
					maxFeePerGas: null,
				})
				.on('receipt', async (r) => {
					await wait

					state.txComplete = true
					state.txInProgress = false
					state.tx = r
				})
				.catch((err) => {
					state.txInProgress = false

					if (wasNotDeclined(err)) {
						state.txError = err
						Bugsnag.notify(err)
					}
				})
		}

		async function executeDeposit() {
			state.txError = null
			state.txInProgress = true
			state.txComplete = false

			if (metamask.state.chain !== import.meta.env.VITE_BPX_CHAIN) {
				const res = await metamask.requestChain(import.meta.env.VITE_BPX_CHAIN)
				if (!res) {
					state.txInProgress = false
					return
				}
			}

			const deposit = await metamask.loadContract(IERC20, BPX_ADDRESS)
			const parts = cleanInput(state.currentExchangeAmt).toString().split('.')
			const integralPart = BigInt(parts[0]) * BigInt(1e9)
			const truncated = BigInt(parts[1] ? parts[1].slice(0, 9).padEnd(9, '0') : '0')
			const decimalPart = BigInt(truncated)
			const amount = integralPart + decimalPart

			const tx = await deposit.methods.transfer(ETH_SAFE_ADDRESS, amount.toString())

			let gasEstimate
			try {
				gasEstimate = await tx.estimateGas({
					from: metamask.state.wallet,
					gas: 500_000,
				})
			} catch (e) {
				console.error({ estimateGas: e, metamask: metamask.state })
				state.txInProgress = false
				state.txError = e.toString()
				return
			}

			gasEstimate = Math.ceil(gasEstimate * 1.3)
			const wait = sleep(1)

			const receipt = await tx
				.send({
					from: metamask.state.wallet,
					gas: gasEstimate,
					maxPriorityFeePerGas: null,
					maxFeePerGas: null,
				})
				.on('receipt', async (r) => {
					await wait

					state.txComplete = true
					state.txInProgress = false
					state.tx = r
				})
				.catch((err) => {
					state.txInProgress = false

					if (wasNotDeclined(err)) {
						state.txError = err
						Bugsnag.notify(err)
					}
				})
		}

		async function updateBalance() {
			if (!metamask.state.wallet) {
				state.balanceOnChain = BigInt(0)
				return
			}

			if (metamask.state.chain != import.meta.env.VITE_BPX_CHAIN) {
				state.balanceOnChain = BigInt(0)
				return
			}

			const contract = await metamask.loadContract(IERC20, BPX_ADDRESS)
			const bal: string = await contract.methods.balanceOf(metamask.state.wallet).call()
			state.balanceOnChain = BigInt(bal)
		}

		metamask.on('connected', updateBalance)
		metamask.on('chainChanged', updateBalance)
		updateBalance()

		function switchSwapState() {
			state.swapStateDeposit = !state.swapStateDeposit
		}

		const addTokenToMetamask = async () => {
			await metamask.watchAsset(
				import.meta.env.VITE_BPX_ADDRESS,
				'BPX',
				9,
				'https://bpx.auction/image/bpx-logo@2000.png'
			)
		}

		function humanReadableToBpx(humanValue: number): bigint {
			const parts = humanValue.toString().split('.')
			const integralPart = BigInt(parts[0]) * BigInt(1e9)
			if (parts.length === 1) {
				return integralPart
			}
			// 9 decimal precision, pad with zeros to 9 digits (1e9)
			const truncated = parts[1].slice(0, 9).padEnd(9, '0')
			const decimalPart = BigInt(truncated)
			return integralPart + decimalPart
		}

		function cleanInput(input: string | number): number {
			const clean = input.toString().replace(/[^0-9.]/g, '')

			// if multiple decimal points, only keep the first
			const parts = clean.split('.')
			if (parts.length > 1) {
				return Number(parts[0] + '.' + parts.slice(1).join(''))
			}

			return Number(clean)
		}

		function maxDeposit() {
			if (state.swapStateDeposit) {
				state.currentExchangeAmt = toFloat(state.balanceOnChain)
			} else {
				state.currentExchangeAmt = toFloat(balanceInMarketWithdrawable.value)
			}
		}

		// Convert Bipixies (no decimals) to BPX (9 decimal places)
		function toFloat(value: bigint): number {
			const integralPart = value / BigInt(1e9)
			const decimalPart = Number(value % BigInt(1e9)) / 1e9
			return Number(integralPart) + decimalPart
		}

		return {
			state,
			executeSwap,
			validateExchangeInput,
			addTokenToMetamask,
			userLinkedWallet,
			newWalletStore,
			switchSwapState,
			metamask,
			humanReadablePrice,
			currencyToDecimal,
			balanceInMarket,
			balanceInMarketWithdrawable,
			humanReadableToBpx,
			maxDeposit,
			toFloat,
		}
	},
})
</script>
