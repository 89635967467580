<template>
	<div class="flex flex-col justify-center items-center">
		<div class="flex justify-center space-x-2">
			<button
				:disabled="props.current <= 1"
				:class="btnProps(props.current - 1)"
				@click="selectPage(props.current - 1)"
			>
				<i class="fa fa-caret-left" />
			</button>

			<button v-for="x in pageRange" :key="`page-btn-${x}`" :class="btnProps(x)" @click="selectPage(x)">
				{{ x }}
			</button>

			<button
				:disabled="props.current >= props.pages"
				:class="btnProps(props.current + 1)"
				@click="selectPage(props.current + 1)"
			>
				<i class="fa fa-caret-right" />
			</button>
		</div>
		<div v-if="props.showJumpButtons" class="flex justify-center space-x-4 mt-6">
			<button :disabled="props.current <= 1" class="text-xs px-4" :class="btnProps(1)" @click="selectPage(1)">
				<i class="fa fa-backward-fast" />
				First Page
			</button>

			<button
				:disabled="props.current >= props.pages"
				class="text-xs px-4"
				:class="btnProps(props.pages)"
				@click="selectPage(props.pages)"
			>
				Last Page
				<i class="fa fa-forward-fast" />
			</button>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { withDefaults, computed } from 'vue'

const props = withDefaults(
	defineProps<{
		pages: number
		current: number
		showPages?: number
		showJumpButtons?: boolean
	}>(),
	{
		showPages: 7,
		showJumpButtons: true,
	}
)

const emit = defineEmits<{
	selectPage: number
}>()

const pageRange = computed(() => {
	let startPage = Math.max(1, props.current - Math.floor(props.showPages / 2))
	const endPage = Math.min(props.pages, startPage + props.showPages - 1)

	if (endPage - props.showPages < startPage) {
		startPage = Math.max(1, endPage - props.showPages)
	}

	let pageArray: number[] = []
	for (let x = startPage; x <= endPage; x++) {
		pageArray.push(x)
	}

	return pageArray
})

function selectPage(num: number) {
	emit('selectPage', num)
}

function btnProps(btn: number) {
	return [
		'leading-0 tracking-tighter rounded shadow-md bg-gray-300',
		'disabled:text-gray-400 enabled:hover:bg-gray-300/80',
		'flex justify-center items-center py-1 w-10',
		props.current == btn ? 'font-bold underline' : '',
	]
}
</script>
