<template>
	<span :title="props.address">{{ ens || props.full ? normalizedAddress : $shorten(normalizedAddress) }}</span>
</template>
<script lang="ts">
import useEns from '@/util/ens'
import { computed, defineComponent, onMounted, PropType } from 'vue'

export default defineComponent({
	props: {
		address: {
			type: String,
			required: true,
		},
		full: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	setup(props) {
		const { reverse, resolved } = useEns(import.meta.env.VITE_ENS_PROVIDER)

		onMounted(() => {
			reverse(props.address)
		})

		const ens = computed(() => {
			return resolved[props.address.toLowerCase()] || null
		})

		const normalizedAddress = computed(() => {
			return props.address.toUpperCase().replace('X', 'x')
		})

		return {
			props,
			ens,
			normalizedAddress,
		}
	},
})
</script>
