import { defineStore } from 'pinia'
import api from '@/util/api'
import { Asset } from '@/types/Asset'
import { useAuthStore } from './AuthStore'
import { Ref, computed, ref } from 'vue'
import { Notice } from '@/types/Notice'
import { BatchClaimStatusResponse, BatchDripClaimResponse } from '@/types/Drip'
import { Offer } from '@/types/Storefront'

const ASSET_POLL_TIMEOUT = 60 * 1000
const NOTICE_POLL_TIMEOUT = 60 * 1000

export const useAccountStore = defineStore('account', () => {
	const authStore = useAuthStore()

	let assetMonInterval
	let noticeMonInterval

	const accountNotices = ref<Notice[]>([])

	function setup() {
		authStore.onEvent('login', () => {
			// console.log('caught login event');
			startUserAssetMonitor(ASSET_POLL_TIMEOUT)
			startUserNoticeMonitor(NOTICE_POLL_TIMEOUT)
		})
		if (userLoggedIn()) {
			// console.log('user is logged in, start monitoring assets');
			startUserAssetMonitor(ASSET_POLL_TIMEOUT)
			startUserNoticeMonitor(NOTICE_POLL_TIMEOUT)
		} else {
			// console.log('user is not logged in');
		}

		return {
			// state
			assets,
			accountNotices,
			offers,

			// actions
			load,
			getAsset,
			storeActivityAccount,
			loadActiveAuctions,
			dismissNotice,
			loadDrips,
			claimDrips,
			batchStatus,
			loadOffers,

			// getters
			assetsById,
		}
	}

	// state
	const assets = ref<Asset[]>([] as Asset[])
	const offers = ref<Offer[]>([] as Offer[])

	// actions
	async function load(forceReload: boolean = false): Promise<Ref<Asset[]>> {
		if (forceReload || 0 == assets.value.length) {
			assets.value = await api.loadUserAssets()
		}

		return assets
	}

	async function loadNotices(forceReload: boolean = false): Promise<Ref<Notice[]>> {
		if (forceReload || 0 == accountNotices.value.length) {
			accountNotices.value = await api.loadUserNotices()
		}

		return accountNotices
	}

	async function dismissNotice(notice: Notice): Promise<Ref<boolean>> {
		return await api.dismissUserNotice(notice.id).then((response: boolean): boolean => {
			loadNotices(true)
			return response
		})
	}

	async function loadActiveAuctions(page: number = 1): Promise<{ data: Object; meta: Object }> {
		return await api.loadUserAuctions(page)
	}

	function getAsset(assetId: string) {
		return assetsById.value[assetId] ?? null
	}

	// getters
	const assetsById = computed(() => {
		return assets.value.reduce((all, a) => {
			all[a.asset_identifier] = a
			return all
		}, {})
	})

	function userLoggedIn() {
		return authStore.authenticated
	}

	async function pollAssets() {
		// console.log('polling for assets');
		return await load(true)
	}

	async function pollNotices() {
		return await loadNotices(true)
	}

	async function storeActivityAccount(page: number = 1, perPage: number = 25) {
		return await api.loadStorefrontActivityAccount(page, perPage)
	}

	function startUserAssetMonitor(timeout: number) {
		if (assetMonInterval) {
			return
		}
		// console.log('starting asset monitor');

		assetMonInterval = setInterval(pollAssets, timeout)
		pollAssets()
	}

	function stopUserAssetMonitor() {
		clearInterval(assetMonInterval)
		assetMonInterval = null
	}

	function startUserNoticeMonitor(timeout: number) {
		if (noticeMonInterval) {
			return
		}

		noticeMonInterval = setInterval(pollNotices, timeout)
		pollNotices()
	}

	const loadDrips = async () => {
		return await api.loadAccountDrips()
	}

	const claimDrips = async (dripIds: string[]): Promise<BatchDripClaimResponse> => {
		return await api.claimDrips(dripIds)
	}

	const batchStatus = async (batchId: string): Promise<BatchClaimStatusResponse | null> => {
		return await api.batchStatus(batchId)
	}

	async function loadOffers(forceReload: boolean = false): Promise<Ref<Offer[]>> {
		if (forceReload || 0 == offers.value.length) {
			offers.value = await api.getUserOffers()
		}
		return offers
	}

	// init
	return setup()
})
