<template>
	<div class="flex flex-row justify-center pr-6">
		<button class="w-full" @click="$emit('tab', 'current')">
			<div class="pb-2 border-b-2" :class="[props.active == 'current' ? activeTabClasses : inactiveTabClasses]">
				<h2 class="text-base lg:text-xl text-center">Balance</h2>
			</div>
		</button>

		<button class="w-full" @click="$emit('tab', 'swap')">
			<div class="pb-2 border-b-2" :class="[props.active == 'swap' ? activeTabClasses : inactiveTabClasses]">
				<h2 class="text-base lg:text-xl text-center">Swap</h2>
			</div>
		</button>
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType, reactive } from 'vue'

export default defineComponent({
	props: {
		active: {
			required: false,
			type: String,
			default: () => 'current',
		},
	},
	emits: ['tab'],
	setup(props) {
		const activeTabClasses = 'border-slate-800 font-bold'
		const inactiveTabClasses = 'border-slate-300'
		return {
			props,
			activeTabClasses,
			inactiveTabClasses,
		}
	},
})
</script>
