<template>
	<template v-if="props.loading">
		<slot name="loading">
			<div class="max-w-screen-md text-center my-12 mx-auto">
				<p class="text-gray-400 text-4xl font-medium tracking-tighter">Loading</p>
				<div class="flex justify-center items-center my-4">
					<Loader />
				</div>
			</div>
		</slot>
	</template>
	<template v-else>
		<slot name="default">Loaded!</slot>
	</template>
</template>
<script lang="ts" setup>
import Loader from '@/components/Loader.vue'
const props = defineProps<{
	loading: boolean
}>()
</script>
