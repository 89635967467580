<template>
	<div class="w-full">
		<div class="">
			<div class="flex justify-center space-x-4 items-center">
				<!-- bpx reward miles -->
				<div class="mt-4 rounded-xl">
					<p class="text-2xl text-center tracking-tight">
						{{ $format(bpxBalance.toDecimal()) }}
						{{ $token }}
					</p>
					<div class="text-center font-medium tracking-tight text-gray-600 space-x-1">
						<p class="inline-block text-lg">Reward Miles</p>
					</div>
				</div>
				<router-link
					class="mt-4 cursor-pointer font-semibold px-3 py-3 whitespace-nowrap md:text-lg tracking-tight rounded-lg overflow-hidden shadow border-2 border-gray-200"
					:to="{ name: 'bulk-transfer' }"
					@click="transferMiles"
				>
					Transfer Miles
				</router-link>
			</div>

			<div class="py-2 mt-4 flex justify-start items-center">
				<img src="/image/blokpax-miles-card-trans.webp" class="w-1/2 flex-shrink flex-grow-0" />
				<div class="text-center w-1/2 flex-shrink-0 flex-grow">
					<router-link
						class="cursor-pointer font-semibold px-3 py-3 whitespace-nowrap md:text-lg tracking-tight rounded-lg overflow-hidden shadow border-2 border-gray-200"
						:to="{ name: 'buy-store-credit' }"
						@click="newWalletStore.toggleBalanceModal()"
					>
						Get More Miles!
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { defineComponent, PropType, computed } from 'vue'
import Currency from '@/types/Currency'
import { useNewWalletStore } from '@/stores/NewWalletStore'
import BN from 'bn.js'
import { useBulkTransferStore } from '@/stores/BulkTransferStore'

const STORE_CREDIT_ENABLED = import.meta.env.VITE_STORE_CREDIT_ENABLED == 'true'
const newWalletStore = useNewWalletStore()
const basketStore = useBulkTransferStore()

const bpxBalance = computed(() => {
	const val = newWalletStore.balances
		.reduce(function (bnBalance, bal) {
			if (bal.slug == 'bpx' || bal.slug == 'vbpx') {
				return bnBalance.add(new BN(bal.available.toString()))
			}

			return bnBalance
		}, new BN('0'))
		.toString()

	return new Currency(val)
})

const pendingAuctionCredit = computed(() => {
	return newWalletStore.balances.find((b) => b.slug == 'usd-auction-credit') ?? null
})

const storeCredit = computed(() => {
	return newWalletStore.balances.find((b) => b.slug == 'usd-store-credit') ?? null
})

async function transferMiles() {
	basketStore.toggleMilesTransfer(true)
	newWalletStore.toggleBalanceModal()
}
</script>
