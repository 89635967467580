<template>
	<section class="bpmp-storefront-collection">
		<header class="bpmp-storefront-collection__header">
			<div class="my-2 flex space-x-2 md:space-x-4 items-stretch">
				<div class="flex-shrink-0 md:w-[20rem]">
					<button class="bpmp-storefront-collection__filter-toggle" @click.prevent="toggleFilters">
						<i class="fa-solid fa-bars-filter"></i>
						<span class="hidden md:inline-block">Filter</span>
					</button>
				</div>

				<filter-summary
					:query="state.assetQuery"
					:filters="storeStore.state.activeFilters"
					@clearQuery="clearSearch"
					@removeFilter="removeAndUpdateFilters"
					@clearAllFilters="
						() => {
							storeStore.clearFilters()
							updateFilterParams()
						}
					"
				/>
			</div>
		</header>

		<aside
			class="bpmp-storefront-collection__aside"
			:class="isSticky ? 'sm:sticky sm:top-[calc(84px+1rem)] sm:h-[calc(100vh-115px)] sm:overflow-auto' : ''"
			v-show="state.isFilterOpen"
		>
			<StorefrontFilters
				class="max-sm:h-full max-sm:flex max-sm:flex-col"
				v-cloak
				@close="toggleFilters"
				@done="toggleFilters"
				@clear="() => storeStore.clearFilters() || updateFilterParams()"
				@clear-all-filters="handleClearAllFilters"
				@filtersUpdated="updateFilterParams"
			/>
		</aside>

		<main class="bpmp-storefront-collection__main">
			<NewActivityTable :data-loader="loadActivity" ref="activityTable" />
		</main>
	</section>
</template>
<script lang="ts" setup>
import { defineComponent, onBeforeMount, onMounted, onUnmounted, PropType, reactive, ref } from 'vue'
import NewActivityTable from '@/components/NewActivityTable.vue'
import { useStorefrontStore } from '@/stores/StorefrontStore'
import { AssetFilter } from '@/types/Storefront'
import router from '@/router'
import StorefrontFilters from '@/components/StorefrontFilters.vue'
import { useRoute } from 'vue-router'
import FilterSummary from '@/components/FilterSummary.vue'

const storeStore = useStorefrontStore()
const route = useRoute()

const isSticky = ref(false)
const activityTable = ref(false)

const state = ref({
	isFilterOpen: false,
	assetQuery: '',
	activeFilters: {} as any,
	filterParams: {} as AssetFilter,
})

const whiteList = {
	...storeStore.state.metadata,
}

onBeforeMount(() => {
	updateFilterParams()
	// data massaging
	// (route.query) object will make values strings if there is only one value.
	// we need the value to always be an array for other components to continue working
	if (Object.entries(storeStore.state.activeFilters).length === 0 && Object.entries(route.query).length > 0) {
		const activeFiltersFromUrlQuery = Object.entries(route.query).reduce((objDataMassaged, keyValPair) => {
			for (const [key, value] of Object.entries(whiteList)) {
				if (keyValPair[0] == key) {
					objDataMassaged[keyValPair[0]] = Array.isArray(keyValPair[1]) ? [...keyValPair[1]] : [keyValPair[1]]
				}
			}

			return objDataMassaged
		}, {})
		storeStore.state.activeFilters = activeFiltersFromUrlQuery
	}
})

onMounted(async () => {
	handleResize()
	updateFilterParams()

	const preContent = document.querySelector('.bpmp-storefront-collection__header') // this class is in @/Components/Storefront.vue

	const obsPreContent = new IntersectionObserver(
		([entry]) => {
			// console.log(entry.isIntersecting)
			entry.isIntersecting ? (isSticky.value = false) : (isSticky.value = true)
		},
		{ threshold: 0.1, rootMargin: '-100px' }
	)

	obsPreContent.observe(preContent)

	window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
	window.removeEventListener('resize', handleResize)
})

async function loadActivity(page: number | null = null): Promise<{ data: Object; meta: Object }> {
	return storeStore.loadActivity(storeStore.state.storefront.slug, page, state.value.filterParams)
}

function clearSearch(withInput: boolean = false) {
	state.value.assetQuery = ''

	updateFilterParams()
}

function updateFilterParams() {
	state.value.filterParams = {
		query: state.value.assetQuery,
		filters: storeStore.state.activeFilters,
	}

	// if active filters is not an empty object,
	// replace url state add it to url as query strings
	if (Object.entries(storeStore.state.activeFilters).length > 0) {
		const urlWithQueries = router.resolve({ query: storeStore.state.activeFilters })
		history.pushState(history.state, null, urlWithQueries.fullPath)
	} else {
		// if active filters is an empty object,
		// remove query strings from url
		history.pushState(history.state, null, router.resolve({ query: {} }).fullPath)
	}

	if (activityTable.value) {
		activityTable.value?.load(1)
	}
}

function toggleFilters() {
	state.value.isFilterOpen = !state.value.isFilterOpen
}

function removeAndUpdateFilters(filter: string, value: string) {
	storeStore.removeFilter(filter, value)
	updateFilterParams()
}

function handleClearAllFilters() {
	storeStore.clearFilters()
	updateFilterParams()
	toggleFilters()
}

const handleResize = () => {
	state.value.isFilterOpen = window.innerWidth >= 768
}
</script>

<style scoped>
@tailwind components;
@layer components {
	.bpmp-storefront-collection {
		@apply w-full
      flex
      flex-row
      flex-wrap
      items-start;
	}

	.bpmp-storefront-collection__header {
		@apply w-full
      p-4;
		/* background-color: red; */
	}

	.bpmp-storefront-collection__aside {
		@apply max-sm:fixed
      max-sm:z-50
      max-sm:bottom-0
      max-sm:bg-white
      max-sm:h-[calc(100vh-72px)]
      max-sm:w-full
      flex-shrink-0
      p-4
      sm:w-[20rem];
	}

	.bpmp-storefront-collection__main {
		@apply flex-grow
      flex-shrink
      p-4
      sm:w-[calc(100%-20rem)]
      self-stretch;
		/* background-color: yellow; */
	}

	.bpmp-storefront-collection__filter-toggle {
		@apply bg-white
      shadow
      px-4
      md:py-2
      aspect-square
      md:aspect-auto
      border
    border-gray-300
      rounded-xl
      space-x-2
      font-medium;
	}

	.bpmp-storefront-collection__search {
		@apply bg-white
    border-gray-300
    text-gray-600
      shadow
      border
      rounded-xl
      py-1
      px-4
      flex
      flex-shrink
      flex-grow
      items-center
      focus-within:ring-1
    focus-within:ring-gray-600;
	}

	.bpmp-storefront-collection__search-input {
		@apply w-full
      border-none
      outline-none
      hover:outline-none
      focus:outline-none
      focus:ring-0
      flex-grow
      flex-shrink;
	}

	.bpmp-storefront-collection__sort {
		@apply fixed
      flex-shrink-0
      shadow-t-md
      border-t-2
    border-gray-300
      inset-0
      top-auto
      h-auto
    bg-gray-50
      px-4
      py-4
      z-50
      md:absolute
      md:z-20
      md:shadow-lg
      md:top-full
      md:bottom-auto
      md:right-0
      md:left-auto
    md:bg-white
      md:min-w-full
      md:border
    md:border-gray-300
      md:mt-2
      md:rounded-xl
      md:px-2
      md:py-2;
	}
	.bpmp-storefront-collection__ {
	}
}
</style>
