import { defineStore } from 'pinia'
import api from '@/util/api'
import { Storefront } from '@/types/Storefront'
import { Asset, AssetList, Listing, NewListing } from '@/types/Asset'

type AssetMap = Record<string, Asset>
declare function emptyObject<T>(): T

export const useAssetStore = defineStore('assets', {
	state: () => ({
		asset: {},
		listing: {},
		bpxPrice: null,
		activityDataAsset: {},
		AssetMap: {
			assetMap: {} as Asset, //emptyObject<Asset>(),
		},
		ownedQuantity: 0,
		//ownsAsset = ownedQuantity ? +ownedQuantity > 0 : false
	}),
	getters: {
		getAssetList: (state) => state.AssetMap,
		getAssetById: (state) => (id: string) =>
			state.AssetMap[id] || {
				id,
				storefront_id: '',
				metadata: { id: '', image: '', attributes: [{ trait_type: '', value: '' }] },
			},
	},
	actions: {
		async createListing(listing: NewListing): Promise<Listing | null> {
			const newListing = await api.createListing(listing)

			if (newListing) {
				// yay listing is made
				return newListing
			}

			return null
		},

		async editListing(id: string, listing: NewListing): Promise<Listing | null> {
			const newListing = await api.editListing(id, listing)

			if (newListing) {
				// yay listing is made
				return newListing
			}

			return null
		},

		async deleteListing(id: string): Promise<boolean> {
			return await api.deleteListing(id)
		},

		async loadActivity(
			storefrontSlug: string,
			assetId: string,
			page: number
		): Promise<{ data: Object; meta: Object }> {
			return api.loadAssetActivity(storefrontSlug, assetId, page)
		},
	},
})
