import metamask from '@/util/metamask'
import BN from 'bn.js'
import ERC20 from '@/abi/IERC20.json'
import web3 from 'web3'
import ethers from 'ethers'
import TxWrapper from '@/types/TxWrapper'

type wallet = string

export const ESCROW_ADDRESS: wallet = import.meta.env.VITE_ESCROW_ADDRESS
export const BPX_ADDRESS: wallet = import.meta.env.VITE_BPX_ADDRESS
export const UINT_MAX64 = new BN(2).pow(new BN(64)).isub(new BN(1))
export const UINT_MAX256 = new BN(2).pow(new BN(256)).isub(new BN(1))
let BPX: web3.eth.Contract | null = null

// console.log('bpx address', BPX_ADDRESS)

function loadBPX(force: boolean | undefined): web3.eth.Contract {
	// console.log('loading bpx address', BPX_ADDRESS)
	if (!BPX || force) {
		BPX = metamask.loadContract(ERC20, BPX_ADDRESS)
	}

	return BPX
}

export async function bpxApproved(wallet: string) {
	return false
	return (
		(await loadBPX(false).methods.allowance(wallet, ESCROW_ADDRESS).call({
			from: wallet,
			gas: 150_000,
		})) == UINT_MAX64
	)
}

export async function approveBpx(wallet: string): Promise<TxWrapper> {
	const tx = loadBPX().methods.approve(ESCROW_ADDRESS, UINT_MAX64)

	const est = tx.estimateGas({
		from: wallet,
		gas: 500_000,
	})

	return new TxWrapper(tx).send({
		from: wallet,
		gas: Math.ceil(est * 1.3),
	})
}

export default {
	ESCROW_ADDRESS,
	BPX_ADDRESS,
	UINT_MAX64,
	BPX,
	bpxApproved,
	approveBpx,
}
