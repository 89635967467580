<template>
	<template v-if="notices.length > 0 && noticeIndex < notices.length">
		<div class="px-4 text-xl py-4 shadow-md" :class="noticeClasses(props.notices[noticeIndex])">
			<div class="max-w-7xl space-x-2 mx-auto flex flex-start items-center">
				<div class="space-x-4 flex items-center w-full justify-start">
					<p class="py-1 flex-grow-0 flex-shrink">
						<i class="text-2xl" :class="noticeIconClasses(props.notices[noticeIndex])"></i>
					</p>
					<p class="flex-grow w-full">{{ props.notices[noticeIndex].notice_text }}</p>
					<p class="flex-grow-0 flex-shrink">
						<button
							class="py-.5 text-gray-600 opacity-50 hover:text-gray-900 hover:opacity-100 cursor-pointer"
							@click.prevent="dismissNotice(noticeIndex)"
						>
							<i class="fas fa-times-circle"></i>
						</button>
					</p>
				</div>
			</div>
		</div>
	</template>
</template>
<script lang="ts" setup>
import { withDefaults, ref } from 'vue'
import { Notice } from '@/types/Notice'

const props = withDefaults(
	defineProps<{
		notices: Notice[]
	}>(),
	{
		notices: () => [] as Notice[],
	}
)

const emit = defineEmits<{
	(e: 'dismiss', notice: Notice): void
}>()

let noticeIndex = ref(0)

function noticeClasses(notice: Notice) {
	return {
		'bg-green-400': notice.notice_type === 'success',
		'bg-yellow-500': notice.notice_type === 'warning' || notice.notice_type === 'alert',
		'bg-red-500': notice.notice_type === 'error',
	}
}

function noticeIconClasses(notice: Notice) {
	return {
		'fas fa-check-circle': notice.notice_type === 'success',
		'fas fa-exclamation-circle': notice.notice_type === 'warning' || notice.notice_type === 'alert',
		'fas fa-times-circle': notice.notice_type === 'error',
	}
}

function dismissNotice(id: number): void {
	if (undefined == props.notices[id]) {
		return
	}

	emit('dismiss', props.notices[id])
}
</script>
