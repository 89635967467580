<template>
	<div v-if="drip !== null">
		<dl class="-my-3 divide-y divide-gray-100 text-sm">
			<div class="grid grid-cols-1 gap-1 py-3 lg:grid-cols-3 lg:gap-4">
				<dt class="font-medium text-gray-900">
					Claimable
					<span class="text-gray-300"><i class="fa fa-info-circle"></i></span>
				</dt>
				<dd class="text-gray-700 lg:col-span-2">
					{{ $format(currencyToDecimal(props.drip.current_value)) }}
					{{ $token }}
				</dd>
			</div>

			<div class="grid grid-cols-1 gap-1 py-3 lg:grid-cols-3 lg:gap-4">
				<dt class="font-medium text-gray-900">Daily Emission</dt>
				<dd class="text-gray-700 lg:col-span-2">
					{{ $format(currencyToDecimal(props.drip.daily_emission)) }}
					{{ $token }}
				</dd>
			</div>

			<div class="grid grid-cols-1 gap-1 py-3 lg:grid-cols-3 lg:gap-4">
				<dt class="font-medium text-gray-900">Start Date</dt>
				<dd class="text-gray-700 lg:col-span-2">
					{{ props.drip.start_date.toFormat('MMMM d, yyyy') }}
				</dd>
			</div>

			<div class="grid grid-cols-1 gap-1 py-3 lg:grid-cols-3 lg:gap-4">
				<dt class="font-medium text-gray-900">End Date</dt>
				<dd class="text-gray-700 lg:col-span-2">
					{{ props.drip.maturity_date.toFormat('MMMM d, yyyy') }}
				</dd>
			</div>

			<div class="grid grid-cols-1 gap-1 py-3 lg:grid-cols-3 lg:gap-4">
				<dt class="font-medium text-gray-900">
					Remaining
					<span class="text-gray-300"><i class="fa fa-info-circle"></i></span>
				</dt>
				<dd class="text-gray-700 lg:col-span-2">
					{{ $format(currencyToDecimal(props.drip.remaining_emission)) }}
					{{ $token }}
				</dd>
				<p class="text-xs text-gray-500 col-span-3">
					{{ $format(currencyToDecimal(props.drip.daily_emission)) }}
					{{ $token }} will be added to the <em>Claimable {{ $token }}</em> for this drip daily at midnight
					UTC, until the end date.
				</p>
			</div>
		</dl>

		<button
			v-if="props.claimable"
			class="btn-primary-lg w-full mt-4 disabled:bg-gray-200 disabled:text-gray-400"
			:disabled="props.drip.current_value === 0"
			@click="startClaim"
		>
			Claim
			{{ $format(currencyToDecimal(props.drip.current_value)) }}
			{{ $token }}
		</button>
		<drip-claim-modal
			v-if="showClaimModal && props.asset"
			:asset="props.asset"
			:drip="props.drip"
			@cancel="() => (showClaimModal = false)"
			@claimed="handleClaimed"
		/>
	</div>
</template>
<script lang="ts" setup>
import { Drip } from '@/types/Drip'
import { Asset } from '@/types/Asset'
import { ref } from 'vue'
import { useDripStore } from '@/stores/DripStore'
import { currencyToDecimal } from '@/util/currencyFormat'
import { Storefront } from '@/types/Storefront'
import DripClaimModal from '@/components/Drips/DripClaimModal.vue'

const dripStore = useDripStore()

const props = withDefaults(
	defineProps<{
		asset: Asset | null
		drip: Drip | null
		storefront: Storefront
		claimable: boolean
	}>(),
	{
		claimable: false,
	}
)

const showClaimModal = ref<boolean>(false)

async function load(): Promise<null> {
	dripStore.load(props.asset.asset_identifier)
	return null
}

function startClaim() {
	showClaimModal.value = true
}

function handleClaimed() {
	alert('You successfully claimed your drip!')
	load()
}
</script>
