<template>
	<section class="px-4">
		<div class="mt-8 flow-root">
			<div class="overflow-x-auto">
				<div class="inline-block min-w-full p-1 align-middle mb-8" v-if="season == '2024'">
					<div
						class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg lg:w-1/2 2xl:w-[48rem] mx-auto"
					>
						<div
							class="py-2 uppercase font-bold text-lg md:text-xl text-center bg-gradient-to-r from-[#a8811f]/100 via-[#e8e2b2]/100 to-[#e8e2b2]/100"
						>
							Legendary Hero
						</div>

						<div class="grid grid-cols-[2fr,3fr] gap-4 p-4">
							<div class="">
								<img
									class="max-w-full rounded-md"
									:src="`/image/color-pops/${sport}-${season}/${slugifyName('BoJax')}.webp`"
								/>
							</div>

							<div>
								<div class="text-3xl md:text-5xl font-poppins font-medium">Bo Jackson</div>

								<div class="text-2xl md:text-4xl font-poppins mt-4">
									<span class="inline-block mr-1 font-medium">{{
										Math.round(boPoints).toLocaleString()
									}}</span>
									<span class="inline-block text-sm/8 md:text-sm/10 align-middle">pts</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="inline-block min-w-full p-1 align-middle">
					<div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
						<table class="table-fixed w-[99%] hero-leaderboard-table min-w-full divide-y divide-gray-300">
							<thead class="bg-gray-50">
								<tr>
									<th
										scope="col"
										class="hidden md:table-cell md:w-2/12 py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6"
									>
										Rank
									</th>
									<th
										scope="col"
										class="w-full sm:w-8/12 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
									>
										Hero
									</th>
									<th
										scope="col"
										class="hidden lg:table-cell lg:w-4/12 px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
									>
										Status
									</th>
									<th
										scope="col"
										class="hidden sm:table-cell sm:w-4/12 px-3 py-3.5 pr-6 text-right text-sm font-semibold text-gray-900"
									>
										Points
									</th>
								</tr>
							</thead>
							<tbody class="divide-y divide-gray-200 bg-white">
								<template v-for="(hero, heroIndex) in heroes" :key="hero.id">
									<tr
										class="hover:bg-slate-50 cursor-pointer"
										@click="
											hero['showDetail']
												? (hero['showDetail'] = !hero['showDetail'])
												: (hero['showDetail'] = true)
										"
										:class="hero['showDetail'] ? 'bg-slate-100 hover:!bg-slate-100' : ''"
									>
										<td class="hidden md:table-cell py-3.5 pl-4 pr-3 text-center">
											<p class="text-3xl font-bold">#{{ heroIndex + 1 }}</p>
										</td>
										<td class="p-4 font-medium text-gray-900 sm:pl-6">
											<div class="flex flex-row text-center justify-start items-center space-x-4">
												<div class="max-w-[40%] sm:max-w-none sm:w-[115px] flex-none">
													<img
														class="max-w-full rounded-md"
														:src="`/image/color-pops/${sport}-${season}/${slugifyName(
															hero.hero.name
														)}.webp`"
													/>
												</div>
												<div class="text-left sm:text-left">
													<div class="text-lg font-bold md:hidden">#{{ heroIndex + 1 }}</div>

													<div class="text-3xl font-poppins">
														{{ hero.hero.name }}
														<span
															class="font-sans lg:hidden text-base/9 align-middle px-2 py-1 font-medium rounded-full text-white whitespace-nowrap"
															:class="
																hero.status !== 'Active' ? `bg-red-600` : 'bg-green-600'
															"
														>
															{{ hero.status }}
														</span>
													</div>
													<div class="text-slate-400 my-2">
														<div class="text-sm">Favorite Player</div>
														<div class="text-lg text-slate-900 font-poppins">
															{{ hero.name }}
														</div>
													</div>
													<div class="sm:hidden text-4xl sm:text-2xl font-poppins mt-4">
														{{ Math.round(hero.hero.total_points).toLocaleString() }}
														<div class="text-sm text-left">pts</div>

														<div
															v-if="hero.multiplier"
															class="bg-red-500 text-sm uppercase py-1 px-2 text-center text-white mt-4 rounded"
														>
															<strong>{{ hero.multiplier_label }}</strong
															><br />
															{{ Number(hero.multiplier).toPrecision(2) }}&times;
															Multiplier
														</div>
													</div>
												</div>
											</div>
										</td>
										<td
											class="hidden lg:table-cell whitespace-nowrap px-3 py-4 text-center text-gray-500"
										>
											<span
												class="font-bold"
												:class="hero.status !== 'Active' ? `text-red-600` : 'text-green-600'"
											>
												{{ hero.status }}
											</span>
										</td>
										<td
											class="hidden sm:table-cell relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
										>
											<div class="text-3xl font-poppins w-min float-right">
												<span class="inline-block mr-1">{{
													Math.round(hero.hero.total_points).toLocaleString()
												}}</span>
												<span class="inline-block text-sm/9 align-middle">pts</span>

												<div
													v-if="hero.multiplier"
													class="bg-red-500 text-sm uppercase py-2 px-4 text-center text-white mt-4 mb-2 rounded"
												>
													<strong>{{ hero.multiplier_label }}</strong
													><br />
													{{ Number(hero.multiplier).toPrecision(3) }}&times; Multiplier
												</div>
											</div>
										</td>
									</tr>
									<tr v-if="hero.showDetail" class="overflow-hidden">
										<td
											class="p-6 w-full overflow-x-auto max-w-[calc(100vw-3rem)]"
											colspan="100%"
											width="100%"
										>
											<!-- @TODO: refactor into <HeroDataTable game="" /> -->
											<p class="text-sm text-center italic mb-2 md:hidden">
												<i class="fa-light fa-arrow-left"></i>
												Scroll side to side to see all data
												<i class="fa-light fa-arrow-right"></i>
											</p>
											<section
												class="hero-leaderboard-table__detail w-full overflow-x-auto whitespace-nowrap border border-gray-300 rounded-lg"
											>
												<table class="w-full divide-y divide-gray-300">
													<thead class="bg-slate-200">
														<tr class="[&>th]:p-2 text-center">
															<th class="sticky left-0 bg-slate-200">Date</th>
															<th>Opp</th>
															<th v-for="heading in tableHeadings[sport]">
																{{ heading }}
															</th>
															<th>Points</th>
														</tr>
													</thead>
													<tbody>
														<tr
															v-for="(game, gameIndex) in hero.gameLog"
															class="even:bg-gray-50"
														>
															<th
																scope="row"
																class="p-2 font-medium text-center sticky left-0"
																:class="gameIndex % 2 !== 0 ? 'bg-gray-50' : 'bg-white'"
															>
																{{ format(new Date(game.game_date), 'MMM. do Y') }}
															</th>
															<td class="p-2 font-medium text-center">
																{{ game.opponent }}
															</td>

															<td
																v-for="stat in tableHeadings[sport]"
																class="p-2 text-center"
															>
																<span v-if="game.stats[stat]">{{
																	game.stats[stat].toLocaleString()
																}}</span>
																<span v-else class="text-gray-300">&mdash;</span>
															</td>

															<td class="p-2 text-center">
																{{
																	Math.round(
																		game.earned_points + game.bonus_points
																	).toLocaleString()
																}}
															</td>
														</tr>
													</tbody>

													<tfoot class="bg-slate-100">
														<tr>
															<th class="sticky left-0 bg-slate-100">&nbsp;</th>
															<th
																v-for="heading of ['G', ...tableHeadings[sport]]"
																class="p-2 text-center"
															>
																{{ heading }}
															</th>
															<th class="p-2 text-center">Points</th>
														</tr>
														<tr class="text-center">
															<th
																scope="row"
																class="pl-2 pb-2 sticky left-0 bg-slate-100"
															>
																{{ season }} Season
															</th>

															<td
																v-for="heading of ['G', ...tableHeadings[sport]]"
																class="pb-2"
															>
																{{
																	hero.stats[heading]
																		? Math.round(hero.stats[heading])
																		: Math.round(0)
																}}
															</td>
															<td class="pb-2">
																{{
																	Math.round(hero.hero.total_points).toLocaleString()
																}}

																<span
																	v-if="hero.multiplier"
																	class="underline text-red-500"
																	:title="`Includes ${Number(
																		hero.multiplier
																	).toPrecision(2)}× ${
																		hero.multiplier_label
																	} multiplier.`"
																	>*</span
																>
															</td>
														</tr>
													</tfoot>
												</table>
											</section>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script lang="ts" setup>
import { useStorefrontStore } from '@/stores/StorefrontStore'
import { format } from 'date-fns'
import { Ref, computed, onBeforeMount, onMounted, ref } from 'vue'

interface Player {
	player_id: string
	sport: 'mlb' | 'nfl' | 'nba' | string
	season: string
	name: string
	position: string
	team: string
	status: string
	injury_status: string
	multiplier: number | null
	multiplier_label: string | null
	updated_at: Date
	hero: Hero
	gameLog: GameLog[]
	stats: any
	showDetail: boolean
}

interface Hero {
	name: string
	multiplier: number
	total_points: number
}

interface GameLog {
	game_date: Date
	opponent: string
	earned_points: number
	bonus_points: number
	stats: any
}

const tableHeadings = {
	mlb: ['1B', '2B', '3B', 'HR', 'RBI', 'R', 'BB', 'HBP', 'SB'],
	nfl: ['Pass Yds', 'Pass TDs', 'Rush Yds', 'Rush TDs', 'Rec Yds', 'Rec TDs', 'Rec'],
	nba: ['PTS', 'REB', 'AST', 'STL', 'BLK', '3PM'],
}

const storefrontStore = useStorefrontStore()
const heroes: Ref<Player[]> = ref([])

const sport = computed<string>(() => {
	const slug = storefrontStore.state.storefront.slug.toLowerCase()

	if (slug.includes('baseball')) {
		return 'mlb'
	} else if (slug.includes('football')) {
		return 'nfl'
	} else if (slug.includes('basketball')) {
		return 'nba'
	} else {
		return ''
	}
})

const season = computed<string>(() => {
	const slug = storefrontStore.state.storefront.slug.toLowerCase()

	const year = slug.match(/(20(\d+))/)

	if (year === null) {
		return ''
	}

	return year[1]
})

const boPoints = computed<number>(() => {
	return heroes.value[0]?.hero.total_points * 1.2 ?? 0
})

function slugifyName(name: string): string {
	name = name.toLowerCase()
	name = name.replace(/\W+/g, '-')
	name = name.replace(/\s+/g, '-')
	return name
}

onBeforeMount(async () => {
	const heroLeaderboardData = await storefrontStore.getHeroLeaderboardData(season.value, sport.value)
	heroes.value = heroLeaderboardData
})
</script>
