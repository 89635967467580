import EventBus from './eventBus'
import Web3 from 'web3/dist/web3.min.js'
import { reactive } from 'vue'

type wallet = string
let ethereum: unknown
let web3: Web3

interface istate {
	initialized: boolean
	present: boolean
	connected: boolean
	connecting: boolean
	chain: number | null
	accounts: wallet[] | null
	wallet: wallet | null
}

class ChainError extends Error {}

const events = new EventBus()

const state: istate = reactive({
	initialized: false,
	present: false,
	connected: false,
	connecting: false,
	chain: null,
	accounts: null,
	wallet: null,
})

export default {
	state,
	ethereum: null,
	init(eth: unknown | undefined, reInit: boolean | undefined) {
		if (undefined === eth || null === eth) {
			web3 = new Web3()
			return
		}

		if (this.state.initialized && !reInit) {
			return
		}

		web3 = new Web3(eth, {
			transactionConfirmationBlocks: 2,
		})

		this.ethereum = eth
		this.state.initialized = true
		this.state.present = true

		this.attachListeners(this.ethereum)
		this.checkConnected().then((connected) => {
			if (connected) {
				events.emit('connected', {
					accounts: this.state.accounts,
					wallet: this.state.wallet,
					chain: this.state.chain,
				})
			}
		})
	},

	attachListeners() {
		if (!this.ethereum) return

		this.ethereum.on('chainChanged', this.chainChanged.bind(this))
		this.ethereum.on('accountsChanged', this.accountChanged.bind(this))
		this.ethereum.on('disconnect', this.disconnected.bind(this))
	},

	async checkConnected(): Promise<boolean> {
		let accounts = await web3.eth.getAccounts()

		// console.log(accounts);
		if (accounts.length === 0) {
			// user has not logged in to metamask or connected metamask to this site
			this.state.connected = false
			this.state.wallet = null
			this.state.accounts = null
		} else {
			this.state.connected = true
			this.state.accounts = accounts
			this.state.wallet = accounts[0]
			this.state.chain = await this.getCurrentChain()
		}

		return this.state.connected
	},

	async connect() {
		if (!this.ethereum) return

		this.state.connecting = true
		this.ethereum
			.request({
				method: 'eth_requestAccounts',
			})
			.then(
				async (accts: wallet[] | null) => {
					await this.checkConnected()
					this.state.connecting = false
					events.emit('connected', {
						accounts: this.state.accounts,
						wallet: this.state.wallet,
						chain: this.state.chain,
					})
				},
				(err: any) => {
					if (err.code === 4001) {
						// eip-1193 userRejectedRequest
						events.emit('error:connect.rejected', err)
						return
					}

					events.emit('error:connect', err)
				}
			)
	},

	async getBalance(wallet: wallet): Promise<number> {
		return await web3.eth.getBalance(wallet)
	},

	async sign(msg: string): Promise<string | null> {
		return await web3.eth.personal
			.sign(web3.utils.utf8ToHex(msg), this.state.wallet?.toLowerCase())
			.catch((err: any) => {
				events.emit('error:sign', err)
				throw err
			})
	},

	verifySignature(msg: string, sig: string, wallet: wallet): boolean {
		const w = web3.eth.accounts.recover(msg, sig)
		return wallet.toLowerCase() == w.toLowerCase()
	},

	async getCurrentChain(): Promise<number | null> {
		const chainID = await this.ethereum.request({
			method: 'eth_chainId',
		})

		return web3.utils.hexToNumber(chainID)
	},

	loadContract(abi: object, address: wallet): Web3.eth.Contract {
		if (!web3) {
			this.init(window.ethereum, false)
		}

		return new web3.eth.Contract(abi, address)
	},

	async requestChain(id: number): Promise<boolean> {
		if (!this.ethereum) {
			return false
		}

		return this.ethereum['request']({
			method: 'wallet_switchEthereumChain',
			params: [
				{
					chainId: web3.utils.numberToHex(id),
				},
			],
		}).then(
			() => {
				return true
			},
			(err: any) => {
				if (err.code == 4902) {
					// this chain is not available from the current MM connection RPC list
					return new ChainError('Chain not available')
				} else if (err.code == -32002) {
					return new ChainError('Chain already active')
				}

				return new ChainError('User declined chain switch')
			}
		)
	},

	async watchAsset(addr: string, symbol: string, decimals: number, image: string) {
		if (!this.ethereum) {
			return false
		}

		return this.ethereum.request({
			method: 'wallet_watchAsset',
			params: {
				type: 'ERC20',
				options: {
					address: addr,
					symbol,
					decimals,
					image,
				},
			},
		})
	},

	chainChanged(id: string) {
		let idNum: number = web3.utils.hexToNumber(id)
		events.emit('chainChanged', idNum)
		this.state.chain = idNum
	},

	accountChanged(accounts: string[]): void {
		if (accounts.length === 0) {
			this.disconnected()
			return
		}

		if (accounts[0] != this.state.wallet) {
			this.state.wallet = accounts[0]
			events.emit('accountChanged', this.state.wallet)
		}
	},

	disconnected() {
		this.state.connected = false
		this.state.accounts = null
		this.state.wallet = null

		events.emit('disconnected')
	},

	getProvider() {
		return web3
	},

	on(event: string, fn: any) {
		events.on(event, fn)
	},

	off(event: string, fn: any) {
		events.off(event, fn)
	},

	once(event: string, fn: any) {
		events.once(event, fn)
	},
}
