import { defineStore } from 'pinia'
import api from '@/util/api'
import { Storefront } from '@/types/Storefront'
import { ExchangeRate } from '@/types/Currency'
import { useAuthStore } from './AuthStore'
import { Asset } from '@/types/Asset'

export const paymentMethods = {
	METHOD_BPX: 'BPX',
	METHOD_STORE_CREDIT: 'STORE-CREDIT',
}

type PaymentMethod = 'BPX' | 'STORE-CREDIT'

export const useMarketStore = defineStore('market', {
	state: () => ({
		storeFronts: [] as Storefront[],
		featuredListings: [] as Asset[],
	}),

	actions: {
		async load(forceReload: boolean = false): Promise<Storefront[]> {
			if (forceReload || 0 == this.storeFronts.length) {
				this.storeFronts = await api.loadStorefronts()
			}

			return this.storeFronts
		},

		async loadFeaturedListings(forceReload: boolean = false): Promise<Asset[]> {
			if (forceReload || 0 == this.featuredListings.length) {
				this.featuredListings = await api.loadFeaturedListings()
			}

			return this.featuredListings
		},

		async placeBid(
			paymentCurrencies: string[],
			bpxBid: string,
			listingID: string
		): Promise<{ success: boolean; is_winning: boolean; high_bid: number; message: string; code?: number }> {
			return await api.placeBid(paymentCurrencies, bpxBid, listingID)
		},

		async buyListing(
			paymentCurrencies: string[],
			listingID: string
		): Promise<{ success: boolean; message: string; code?: number }> {
			return await api.buyListing(paymentCurrencies, listingID)
		},

		async otcTransfer(
			assets: { asset_id: string; quantity: number }[],
			recipient: string,
			pin: string | null
		): Promise<{ success: boolean; message: string; code?: number | string | null }> {
			return await api.transferAssets(assets, recipient, pin)
		},

		async initiateStoreCreditPurchase(
			amount: number,
			redirect: string | null = null
		): Promise<{ success: boolean; message?: string; redirect?: string }> {
			return await api.initiateStoreCreditPurchase(amount, redirect)
		},

		async getMilesPackages() {
			return await api.getMilesPackages()
		},
	},

	getters: {
		byID() {
			if (this.storeFronts.length == 0) {
				return {}
			}

			return this.storeFronts.reduce((all, storefront) => {
				all[storefront.id] = storefront
				return all
			}, {})
		},
	},
})
