<template>
	<div
		class="flex flex-col min-h-screen"
		:class="[
			appStore.mobileOnlyOverlay || appStore.overlay ? 'h-screen overflow-hidden' : '',
			appStore.mobileOnlyOverlay ? 'md:h-auto md:overflow-visible' : '',
		]"
	>
		<component :is="route.meta.layout || AppLayout">
			<router-view id="app-main" class="pb-12 min-h-screen" :key="route.fullPath.replace(/(#.*)$/, '')" />
		</component>
	</div>
</template>
<script setup lang="ts">
import { useRoute } from 'vue-router'
import AppLayout from '@/layouts/App.vue'
import { useAppStore } from './stores/AppStore'

const appStore = useAppStore()

const route = useRoute()
</script>
