<template>
	<feedback-modal @close="close">
		<template #title>
			<template v-if="state.isInProgress === false">
				<span class="text-slate-900 font-poppins" v-if="initalState">
					<span
						class="inline-flex justify-center items-center text-orange-400 bg-orange-100 aspect-square py-2 px-6 rounded-full"
						><i class="fa-solid fa-exclamation"></i
					></span>
					<span class="ml-3">Are you sure?</span>
				</span>
				<span v-else-if="errorState">
					<span
						class="inline-flex justify-center items-center text-red-400 bg-red-100 aspect-square py-2 px-6 rounded-full"
						><i class="fa-solid fa-exclamation"></i
					></span>
					<span class="ml-3">Error</span>
				</span>
				<span v-else-if="successState">
					<span
						class="inline-flex justify-center items-center text-green-400 bg-green-100 aspect-square py-2 px-6 rounded-full"
						><i class="fa-solid fa-check"></i
					></span>
					<span class="ml-3">Success!</span>
				</span>
			</template>
			<template v-else>
				<span class="text-slate-900 font-poppins">
					<span
						class="inline-flex justify-center items-center text-orange-400 bg-orange-100 aspect-square py-2 px-6 rounded-full"
						><i class="fa-solid fa-timer"></i
					></span>
					<span class="ml-3">Please wait...</span>
				</span>
			</template>
		</template>
		<template #body>
			<div v-if="state.isInProgress">
				<div class="flex flex-col h-full justify-center items-center">
					<p class="mb-4">Cancelling offer...</p>
					<i class="fa-2xl fa-sharp fa-solid fa-spinner-third fa-spin"></i>
				</div>
			</div>
			<div v-else>
				<div v-if="initalState">
					<p class="text-lg mt-4 mb-8">Are you sure you want to cancel this offer?</p>
					<div class="mt-4">
						<div class="flex justify-between items-center bg-gray-100 mt-4 rounded-lg p-4">
							<template v-if="props.offer.offer_for_asset && props.asset">
								<p class="text-lg font-bold">Asset</p>
								<p class="text-xl">{{ props.asset.name }}</p>
							</template>
							<template v-else>
								<p class="text-lg font-bold">Trait</p>
								<p class="text-xl">
									<span
										v-if="
											props.offer.offer_for_trait_prop === null &&
											props.offer.offer_for_trait_val === null
										"
										>Any Trait</span
									>
									<span v-else>
										{{ props.offer.offer_for_trait_prop }}: {{ props.offer.offer_for_trait_val }}
									</span>
								</p>
							</template>
						</div>

						<div class="flex justify-between">
							<div>
								<p class="text-lg mt-4 font-bold">Offer Amount</p>
								<p class="text-xl">
									{{
										$format(humanReadablePrice(BigInt(props.offer.offer_bpx_per_token).toString()))
									}}
									{{ $token }}
								</p>
							</div>

							<div>
								<p class="text-lg mt-4 font-bold">Total Volume</p>
								<p class="text-xl">
									{{
										$format(
											humanReadablePrice(
												(
													BigInt(props.offer.offer_bpx_per_token) *
													BigInt(props.offer.total_offer_token_count)
												).toString()
											)
										)
									}}
									{{ $token }}
								</p>
							</div>
							<div>
								<p class="text-lg mt-4 font-bold">Remaining</p>
								<p class="text-xl">
									{{ props.offer.total_offer_token_count - props.offer.tokens_fulfilled }} /
									{{ props.offer.total_offer_token_count }}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div v-else-if="errorState">
					<p class="text-lg mt-4">Something went wrong. Please try again later.</p>
				</div>
				<div v-else-if="successState">
					<p class="text-lg mt-4">Offer cancelled successfully!</p>
				</div>
			</div>
		</template>
		<template #buttons="{ close }">
			<div class="flex justify-end items-center space-x-4">
				<button
					v-if="!state.isInProgress"
					class="btn-lg shadow-none bg-gray-300 text-slate-900 mt-2 hover:bg-gray-200"
					@click.stop="close"
				>
					{{ state.isError || state.isSuccess ? 'Close' : 'No, nevermind' }}
				</button>
				<button
					v-if="initalState"
					class="btn-lg mt-2 disabled:bg-red-600/20 disabled:text-red-900 bg-red-700 text-white hover:bg-red-600"
					:disabled="state.isInProgress"
					@click.stop="cancelOffer"
				>
					<span v-if="state.isInProgress === false">Cancel Offer</span>
					<span v-else>Deleting <i class="fa fa-spinner fa-spin"></i></span>
				</button>
			</div>
		</template>
	</feedback-modal>
</template>

<script lang="ts" setup>
import FeedbackModal from '@/components/modals/FeedbackModal.vue'
import { reactive, computed } from 'vue'
import { Offer } from '@/types/Storefront'
import { useStorefrontStore } from '@/stores/StorefrontStore'
import { useAuthStore } from '@/stores/AuthStore'
import { humanReadablePrice } from '@/util/currencyFormat'
import { Asset } from '@/types/Asset'

const props = defineProps<{
	offer: Offer | null
	asset?: Asset | Pick<Asset, 'id' | 'name' | 'image'> | null
}>()

const emit = defineEmits<{
	(event: 'close'): void
	(event: 'reload'): void
}>()

const authStore = useAuthStore()
const storeStore = useStorefrontStore()
const state = reactive({
	isInProgress: false,
	isSuccess: false,
	isError: false,
})

const initalState = computed(() => state.isError === false && state.isSuccess === false)
const errorState = computed(() => state.isError === true && state.isSuccess === false)
const successState = computed(() => state.isError === false && state.isSuccess === true)
const remainingMiles = computed(() => {
	if (props.offer) {
		return (
			BigInt(props.offer.offer_bpx_per_token) *
			BigInt(props.offer.total_offer_token_count - props.offer.tokens_fulfilled)
		).toString()
	}
	return '0'
})

async function cancelOffer() {
	state.isInProgress = true
	try {
		const response = await storeStore.cancelStorefrontOffer(props.offer)
		if (response.success) {
			state.isSuccess = true
			state.isInProgress = false
		} else {
			console.error('Failed to cancel offer', response.message)
			state.isError = true
			state.isInProgress = false
		}
	} catch (e) {
		console.error('Something went wrong', e)
		state.isError = true
		state.isInProgress = false
	}
}

function close() {
	if (state.isError || state.isSuccess) {
		emit('reload')
	} else {
		emit('close')
	}
}
</script>
