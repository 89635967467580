<template>
	<account-layout-with-nav>
		<section class="relative my-6 px-4 mt-4">
			<div class="relative md:flex mt-6">
				<div v-if="!state.isWalletConnected" class="flex-grow md:ml-4">
					<p class="mb-4">Wallet Not Connected.</p>

					<button @click="connectWallet" class="p-4 rounded-md bg-sky-600 hover:bg-sky-700 text-white">
						Connect Wallet
					</button>
				</div>
				<div v-else class="flex-grow md:ml-4">
					<div class="flex items-center space-x-2">
						<p v-if="state.loading">
							<loading-placeholder class="w-36 rounded-lg">&nbsp;</loading-placeholder>
						</p>
						<p v-else class="font-medium">
							{{ $format(totalItems) }} <pluralize :amount="totalItems" singular="item" plural="items" />
						</p>
					</div>

					<div class="flex flex-wrap gap-2 mt-2">
						<div v-if="activeFilterCount > 0" class="hidden md:block">
							<filter-summary
								:filters="storeStore.state.activeFilters"
								@removeFilter="storeStore.removeFilter"
								class="flex-wrap my-1 first:ml-0 last:mr-0"
							>
								<template #default v-if="state.filteredStorefrontIDs.length">
									<div
										v-for="storefront in filteredStorefronts"
										:key="`f-sf-${storefront.id}`"
										class="bg-gray-200/80 text-gray-900 rounded-lg px-4 flex justify-center items-center space-x-2"
									>
										<storefront-filter-item class="max-w-[18rem]" :storefront="storefront" />
										<button
											class="min-w-[2rem] flex-shrink-0"
											@click.prevent="toggleStorefront(storefront)"
										>
											<span><i class="fa fa-times" /></span>
										</button>
									</div>
								</template>
							</filter-summary>
						</div>

						<div v-if="pagedAssets.pages" class="text-right text-gray-400 text-sm flex-grow self-end">
							Viewing page {{ pagedAssets.currentPage + 1 }} of {{ pagedAssets.pages }}
						</div>
					</div>
					<div class="mt-2">
						<tile-loader
							v-if="state.loading"
							:count="36"
							class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4"
						>
							<loading-tile />
						</tile-loader>
						<div v-else>
							<div v-if="pagedAssets.assets.length === 0">
								<p>
									No eligible assets are detected in wallet. Buy some at
									<a
										class="text-sky-600 underline hover:no-underline hover:text-sky-400"
										href="https://collecthth.com"
										target="_blank"
										>https://CollectHTH.com</a
									>!
								</p>
							</div>
							<div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
								<WalletAsset
									v-for="asset in pagedAssets.assets"
									:key="`asset-${asset?.rawMetadata?.id}`"
									:asset="asset"
									@asset-transferred="handleTransferComplete"
								/>
							</div>
							<div class="mt-4 flex justify-between">
								<paginator
									:pages="pagedAssets.pages"
									:current="pagedAssets.currentPage + 1"
									:show-jump-buttons="false"
									@select-page="(pg) => (state.currentPage = pg - 1)"
								/>

								<div v-if="pagedAssets.pages" class="text-right text-gray-400 text-sm self-start">
									Viewing page {{ pagedAssets.currentPage + 1 }} of {{ pagedAssets.pages }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</account-layout-with-nav>
</template>

<script lang="ts" setup>
import { defineComponent, onMounted, PropType, reactive, computed, onBeforeMount } from 'vue'

import { useWalletStore } from '@/stores/WalletStore'
import { useMarketStore } from '@/stores/MarketStore'

import { Asset, Attribute } from '@/types/Asset'
import AccountLayoutWithNav from '@/layouts/AccountLayoutWithNav.vue'
import StorefrontAsset from '@/components/StorefrontAsset.vue'
import StorefrontFilterItem from '@/components/StorefrontFilterItem.vue'
import StorefrontFilters from '@/components/StorefrontFilters.vue'
import FilterSummary from '@/components/FilterSummary.vue'
import AccountCollectionFilters from '@/components/AccountCollectionFilters.vue'
import Paginator from '@/components/widgets/Paginator.vue'
import { useAccountStore } from '@/stores/AccountStore'
import TileLoader from '@/components/TileLoader.vue'
import { useRoute } from 'vue-router'
import ActivityTable from '@/components/ActivityTable.vue'
import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue'
import LoadingTile from '@/components/LoadingTile.vue'
import { Storefront } from '@/types/Storefront'
import { useStorefrontStore } from '@/stores/StorefrontStore'
import { useAppStore } from '@/stores/AppStore'
import { OwnedToken } from '@/types/Asset'
import WalletAsset from '@/components/WalletAsset.vue'
import metamask from '@/util/metamask'

const marketStore = useMarketStore()
const accountStore = useAccountStore()
const storeStore = useStorefrontStore()
const appStore = useAppStore()
const route = useRoute()

const state = reactive({
	ownedTokens: [] as OwnedToken[],
	loading: true,
	showCollectionFilters: true,
	filteredStorefrontIDs: [] as string[],

	//pagination
	pageSize: 102,
	currentPage: 0,

	isWalletConnected: false,
})

onBeforeMount(async () => {
	state.isWalletConnected = await metamask.checkConnected()
})

onMounted(async () => {
	toggleCollectionFilters(window.innerWidth > 768)
	loadUserAssetsFromWallet()

	storeStore.on('filtersUpdated', (filters) => {
		state.currentPage = 0
	})

	metamask.on('connected', async () => {
		loadUserAssetsFromWallet()
	})
	metamask.on('accountChanged', async () => {
		loadUserAssetsFromWallet()
	})
})

async function getOwnedTokens() {
	const walletStore = useWalletStore()
	const marketStore = useMarketStore()
	const storeFronts = await marketStore.load()
	const contracts: string[] = []

	for (let i = 0; i < storeFronts.length; i++) {
		// "0x4a39cBcA3F2EBaE1053575b4b2199AA5ba16B08c:137"
		contracts.push(`${storeFronts[i].contract_address}:${storeFronts[i].network_id}`)
	}

	const ownership = await walletStore.getWalletOwnership(contracts)

	return ownership
}

const activeFilterCount = computed(() => {
	return (
		Object.keys(storeStore.state.activeFilters).reduce((c, key) => storeStore.state.activeFilters[key].length, 0) +
		(state.filteredStorefrontIDs.length ?? 0)
	)
})

async function loadUserCollection(force: boolean = false) {
	return Promise.all([marketStore.load(), accountStore.load(force)])
}

const assetStorefronts = computed(() => {
	if (state.loading) return []

	return marketStore.storeFronts.filter(function (sf) {
		for (const asset of accountStore.assets) {
			if (asset.storefront.id == sf.id) {
				return true
			}
		}

		return false
	})
})

const filteredStorefronts = computed(() => {
	if (state.loading) return []

	return assetStorefronts.value.filter((storefront) => {
		return state.filteredStorefrontIDs.indexOf(storefront.id) >= 0
	})
})

const assets = computed(() => {
	if (state.loading) return []

	const filterResult = state.ownedTokens.filter((asset) => {
		// filter this asset out if there is one or more storefronts selected
		// and this asset isn't in any of them.
		if (state.filteredStorefrontIDs.length > 0 && state.filteredStorefrontIDs.indexOf(asset.storefront.id) == -1) {
			return false
		}

		if (storeStore.state.activeFilters['Listed'] !== undefined) {
			if (storeStore.state.activeFilters['Listed'].indexOf('Yes') >= 0) {
				if ((asset.listings ?? []).length == 0) {
					return false
				}
			}
		}

		if (state.filteredStorefrontIDs.length != 1) {
			return true
		}

		const activeFilters = Object.values(storeStore.state.activeFilters).filter((f) => f.length)

		if (activeFilters.length == 0) {
			return true
		}

		let matches = true
		for (const key in storeStore.state.activeFilters) {
			if (key == 'Listed') continue

			let keyMatches = false
			for (const attr of asset.attributes) {
				if (attr.trait_type == key) {
					if (storeStore.state.activeFilters[key].indexOf(attr.value) >= 0) {
						keyMatches = true
						break
					}
				}
			}

			matches = matches && keyMatches
			if (!matches) {
				break
			}
		}

		return matches
	})

	return filterResult
})

const pagedAssets = computed(() => {
	const page = {
		pages: Math.ceil(assets.value.length / state.pageSize),
		currentPage: state.currentPage,
		totalItems: assets.value.length,
		assets: assets.value.slice(
			state.currentPage * state.pageSize,
			state.currentPage * state.pageSize + state.pageSize
		),
	}

	return page
})

const totalItems = computed(() => {
	return assets.value.reduce((total, asset) => total + asset.balance, 0)
})

function storefrontSelected(storefront: Storefront) {
	return state.filteredStorefrontIDs.indexOf(storefront.id) >= 0
}

function toggleStorefront(storefront: Storefront) {
	let currentIndex = state.filteredStorefrontIDs.indexOf(storefront.id)
	if (currentIndex >= 0) {
		state.filteredStorefrontIDs.splice(currentIndex, 1)
	} else {
		state.filteredStorefrontIDs.push(storefront.id)
	}

	if (state.filteredStorefrontIDs.length == 1) {
		storeStore.loadStore(state.filteredStorefrontIDs[0])
	}

	storeStore.clearFilters()
}

function clearFilters() {
	state.filteredStorefrontIDs = []
	storeStore.clearFilters()
}

function toggleCollectionFilters(force: boolean) {
	state.showCollectionFilters = undefined == force ? !state.showCollectionFilters : force

	appStore.mobileOnlyOverlay = state.showCollectionFilters
}

async function connectWallet() {
	state.loading = true
	await metamask.connect()
}

async function loadUserAssetsFromWallet() {
	state.loading = true
	const tokens = await getOwnedTokens()
	state.ownedTokens = tokens.flat()
	state.isWalletConnected = true
	state.loading = false
}

async function handleTransferComplete() {
	loadUserAssetsFromWallet()
}
</script>
