<template>
	<div class="bg-slate-100 rounded-lg text-lg text-gray-800 flex items-stretch self-center divide-x divide-slate-300">
		<button
			id="show-modal"
			@click="newWalletStore.toggleBalanceModal()"
			class="rounded-l-lg hover:bg-slate-300 transition-colors h-13"
		>
			<div class="leading-none flex gap-2 items-center px-4">
				<div><i class="fa fa-wallet text-xl" /></div>
				<div class="hidden md:block">
					<span v-if="readableBpxBalance > 0">
						{{ $format(readableBpxBalance) }}
						{{ $token }}
					</span>
					<span v-else-if="readablePendingAuctionCredit > 0">
						${{ $format(readablePendingAuctionCredit / 100) }} USD<sup>*</sup>
					</span>
					<span v-else-if="readableAuctionCredit > 0">
						${{ $format(readableAuctionCredit) }} {{ $token }}
					</span>
				</div>
			</div>
		</button>

		<Menu as="div" class="p-1 relative text-left text-black rounded-r-lg hover:bg-slate-300 transition-colors">
			<MenuButton class="block relative self-center cursor-pointer">
				<div class="flex h-10 md:px-2 items-center gap-1">
					<div class="w-10 px-1 hidden md:block">
						<img
							:src="`https://id.blokpax.com/avatar/@${authStore.user.username}?size=128`"
							:alt="`@${authStore.user.username}`"
							class="block size-8 rounded-full overflow-hidden"
						/>
					</div>
					<div class="leading-none justify-center items-center hidden md:flex select-none">
						@{{ authStore.user.username }}
					</div>
					<div class="w-10 px-1 md:hidden">
						<img
							:src="`https://id.blokpax.com/avatar/@${authStore.user.username}?size=128`"
							:alt="`@${authStore.user.username}`"
							class="block size-8 rounded-full overflow-hidden"
						/>
					</div>
				</div>
			</MenuButton>

			<transition
				enter-active-class="transition ease-out duration-100"
				enter-from-class="transform opacity-0 scale-95"
				enter-to-class="transform opacity-100 scale-100"
				leave-active-class="transition ease-in duration-75"
				leave-from-class="transform opacity-100 scale-100"
				leave-to-class="transform opacity-0 scale-95"
			>
				<MenuItems
					class="absolute right-0 z-10 mt-2 w-max-content origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-[calc(100vw-2rem)] md:w-[420px] overflow-hidden"
				>
					<div class="px-4 py-3">
						<p v-if="!authStore.authenticated" class="whitespace-nowrap">
							No Account?
							<a
								href="https://app.blokpax.com/sign-up"
								title="Sign up to get started!"
								class="text-yellow-600 hover:underline"
								>Sign up!</a
							>
						</p>
						<p v-else class="whitespace-nowrap text-sm">Signed in as @{{ authStore.user.username }}</p>
					</div>

					<div class="" v-if="authStore.authenticated">
						<MenuItem v-slot="{ active, close }">
							<form method="post" action="https://app.blokpax.com/auth/handoff">
								<input name="token" :value="accessToken" type="hidden" />
								<button
									type="submit"
									class="w-full text-left text-gray-700 block px-4 py-3 text-sm hover:bg-gray-100 hover:text-gray-900 hover:cursor-pointer"
									@click="
										() => {
											close()
										}
									"
								>
									<div class="w-5 h-5 inline-block">
										<i class="fas fa-candy"></i>
									</div>
									My Packs
								</button>
							</form>
						</MenuItem>
					</div>

					<div class="" v-if="authStore.authenticated">
						<MenuItem v-slot="{ active, close }">
							<router-link
								v-slot="{ navigate }"
								:to="{ name: 'account' }"
								:class="[
									active ? 'bg-gray-100 text-gray-900 cursor-pointer' : 'text-gray-700',
									'block px-4 py-3 text-sm',
								]"
							>
								<div
									@click="
										() => {
											close()
											navigate()
										}
									"
								>
									<div class="w-5 h-5 inline-block">
										<i class="fas fa-rectangle-vertical-history"></i>
									</div>
									My Collection
								</div>
							</router-link>
						</MenuItem>
					</div>

					<div class="" v-if="authStore.authenticated && flagStore.checkFlag(Flags.FEATURE_BATCH_DRIP_CLAIM)">
						<MenuItem v-slot="{ active, close }">
							<router-link
								v-slot="{ navigate }"
								:to="{ name: 'account:drips' }"
								:class="[
									active ? 'bg-gray-100 text-gray-900 cursor-pointer' : 'text-gray-700',
									'block px-4 py-3 text-sm',
								]"
							>
								<div
									@click="
										() => {
											close()
											navigate()
										}
									"
								>
									<div class="w-5 h-5 inline-block">
										<i class="fa-sharp fa-solid fa-droplet"></i>
									</div>
									Drips
								</div>
							</router-link>
						</MenuItem>
					</div>

					<div class="">
						<MenuItem v-slot="{ active }" v-if="!authStore.authenticated">
							<span
								@click="handleLoginClicked"
								:class="[
									active ? 'bg-gray-100 text-gray-900 cursor-pointer' : 'text-gray-700',
									'block px-4 py-3 text-sm',
								]"
							>
								<div class="w-5 h-5 inline-block">
									<i class="fas fa-sign-in"></i>
								</div>
								Log In
							</span>
						</MenuItem>
						<MenuItem v-slot="{ active, close }" v-else>
							<button
								@click="
									() => {
										close()
										authStore.logout()
									}
								"
								type="submit"
								:class="[
									active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
									'block w-full px-4 py-3 text-left text-sm',
								]"
							>
								<div class="w-5 h-5 inline-block">
									<i class="fa fa-sign-out"></i>
								</div>
								Sign out
							</button>
						</MenuItem>
					</div>
				</MenuItems>
			</transition>
		</Menu>

		<DepositModal v-if="newWalletStore.balanceModalVisible" />
	</div>
</template>
<script lang="ts" setup>
import { useAuthStore } from '@/stores/AuthStore'
import { useNewWalletStore } from '@/stores/NewWalletStore'
import { defineComponent, PropType, computed, onMounted, reactive, watch, ref } from 'vue'
import DepositModal from './DepositModal.vue'
import { BN } from 'bn.js'
import Currency from '@/types/Currency'
import { useRoute } from 'vue-router'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Flags } from '@/types/Flags'
import { useFlagStore } from '@/stores/FlagStore'

const authStore = useAuthStore()
const newWalletStore = useNewWalletStore()
const flagStore = useFlagStore()

const route = useRoute()

const readableBpxBalance = computed(() => {
	return new Currency(
		newWalletStore.balances.reduce(function (bnBalance, bal) {
			if (bal.slug == 'bpx' || bal.slug == 'vbpx') {
				return bnBalance.add(new BN(bal.available.toString()))
			}

			return bnBalance
		}, new BN('0'))
	).toDecimal({ showDecimals: false })
})

const readablePendingAuctionCredit = computed(() => {
	return newWalletStore.balances.find((b) => b.slug == 'usd-auction-credit')?.available ?? 0
})

const readableAuctionCredit = computed(() => {
	return new Currency(
		newWalletStore.balances.find((b) => b.slug == 'auction-credit')?.available ?? new BN('0')
	).toDecimal()
})

async function handleLoginClicked() {
	authStore.redirectToLogin(route.path)
}

const accessToken = computed(() => {
	if (!authStore.authenticated) {
		return null
	}
	return authStore.token?.data.accessToken
})
</script>
