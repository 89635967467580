<template>
	<account-layout-with-nav>
		<section v-if="state.isLoading" class="flex flex-col justify-center items-center">
			<p class="my-4">Loading...</p>
			<i class="fa-2xl fa-sharp fa-solid fa-spinner-third fa-spin"></i>
		</section>
		<section v-else>
			<header
				class="bg-gray-50 py-4 px-8 sticky top-[4.5rem] lg:top-[5rem] z-20 border-b border-gray-300 h-16 flex items-center"
			>
				<button
					class="shadow px-4 md:py-2 aspect-square md:aspect-auto border border-gray-300 rounded-xl space-x-2 font-medium mr-4"
					@click.prevent="toggleFilters"
				>
					<i class="fa-solid fa-bars-filter"></i>
					<span class="hidden md:inline-block">Filter</span>
				</button>

				<p>{{ state.offers.length }} Offers</p>
			</header>

			<!-- main area -->
			<div class="flex">
				<!-- filters -->
				<div
					class="transition-all w-screen top-[4.5rem] h-[calc(100vh-4.5rem)] p-4 border-r overflow-y-auto fixed z-30 bg-gray-50 border-gray-300 lg:z-0 lg:sticky lg:max-w-xs lg:h-[calc(100vh-9rem)] lg:top-[9rem] lg:translate-x-0"
					:class="{
						'top-full': !state.isFilterOpen,
						'top-0': state.isFilterOpen,
						'lg:hidden': !state.isFilterOpen,
						'lg:block': state.isFilterOpen,
					}"
				>
					<account-offers-filters
						:offer-storefronts="offerStorefronts"
						:filtered-storefronts="filteredStorefronts"
						:selected-statuses="state.selectedStatuses"
						@clear-all-filters="clearAllFilters"
						@toggle-storefront="handleToggleStorefront"
						@update-status="handleToggleStatus"
						@done="toggleFilters"
						v-if="state.isFilterOpen"
					/>
				</div>

				<!-- offers -->
				<div class="flex-grow">
					<filter-summary
						v-if="state.filteredStorefrontIDs.length"
						:filters="storeStore.state.activeFilters"
						@removeFilter="storeStore.removeFilter"
						@clear-all-filters="clearAllFilters()"
						class="flex-wrap p-4 first:ml-0 last:mr-0"
					>
						<template #default>
							<div
								v-for="storefront in filteredStorefronts"
								:key="`f-sf-${storefront.id}`"
								class="bg-gray-200/80 text-gray-900 rounded-lg px-4 flex justify-center items-center space-x-2"
							>
								<storefront-filter-item class="max-w-[18rem]" :storefront="storefront" />
								<button
									class="min-w-[2rem] flex-shrink-0"
									@click.prevent="handleToggleStorefront(storefront)"
								>
									<span><i class="fa fa-times" /></span>
								</button>
							</div>
						</template>
					</filter-summary>
					<div class="sticky top-[8.5rem] lg:top-[9rem] bg-gray-100 border-b border-gray-300 z-20">
						<div
							class="grid grid-cols-1 md:grid-cols-[5fr,2fr,2fr,2fr,3fr,2fr] items-center justify-center h-16 px-4 py-2 md:py-4"
						>
							<p class="text-left">Offer</p>
							<p class="text-left hidden md:block">Amount</p>
							<p class="flex-grow hidden md:block">Total Volume</p>
							<p class="flex-grow hidden md:block">Available</p>
							<p class="flex-grow hidden md:block">Expires</p>
						</div>
					</div>
					<div class="p-4 flex justify-center" v-if="state.offers.length === 0">
						No current offers available
					</div>
					<article
						v-else
						v-for="offer of offers"
						@click="handleOfferClick(offer)"
						class="grid grid-cols-1 md:grid-cols-[5fr,2fr,2fr,2fr,3fr,2fr] p-4 cursor-pointer hover:bg-gray-100 items-center group odd:bg-slate-50 even:bg-slate-100"
					>
						<div class="flex-grow shrink-0 flex flex-row md:space-x-4 md:items-center">
							<div class="flex h-16 w-16 border rounded-lg overflow-hidden">
								<img
									v-if="offer.asset"
									:src="$cdnify(offer.asset.image, 128)"
									:alt="offer.asset.name"
									class="mx-auto"
								/>
								<img
									v-else="offer.asset"
									:src="getStorefront(offer.storefront_id).pfp_image"
									:alt="getStorefront(offer.storefront_id).name"
									class="mx-auto"
								/>
							</div>
							<div class="ml-4 md:ml-0">
								<p class="font-bold">{{ getStorefront(offer.storefront_id).name }}</p>
								<p>
									<span v-if="offer.asset">
										{{ offer.asset.name }}
									</span>
									<span
										v-else-if="
											offer.offer_for_trait_prop !== null && offer.offer_for_trait_val !== null
										"
										class="bg-gray-200 rounded py-1 px-2 text-xs inline-block"
									>
										<strong>{{ offer.offer_for_trait_prop }}:</strong>
										{{ offer.offer_for_trait_val }}
									</span>
									<span v-else> Any </span>
								</p>
							</div>
						</div>
						<div class="flex-grow md:mt-0 mt-4 flex flex-row">
							<div class="md:hidden mr-2 font-medium">Amount:</div>
							<div>
								{{ $format(humanReadablePrice(BigInt(offer.offer_bpx_per_token).toString())) }}
								{{ $token }}
							</div>
						</div>
						<div class="flex-grow flex flex-row">
							<div class="md:hidden mr-2 font-medium">Total Volume:</div>
							<div>
								{{
									$format(
										humanReadablePrice(
											(
												BigInt(offer.offer_bpx_per_token) *
												BigInt(offer.total_offer_token_count - offer.tokens_fulfilled)
											).toString()
										)
									)
								}}
								{{ $token }}
							</div>
						</div>

						<div class="flex-shrink flex flex-row">
							<div class="md:hidden mr-2 font-medium">Available:</div>
							<div>
								{{ offer.total_offer_token_count - offer.tokens_fulfilled }}
							</div>
						</div>

						<div class="flex-shrink flex flex-row">
							<div class="md:hidden mr-2 font-medium">Expires At:</div>

							<div>
								<template
									v-if="DateTime.fromISO(offer.expires_at).minus({ hours: 24 }) > DateTime.now()"
								>
									{{ DateTime.fromISO(offer.expires_at).toFormat('D') }}
								</template>

								<!-- tomorrow -->
								<template
									v-else-if="
										DateTime.fromISO(offer.expires_at).day === DateTime.now().plus({ day: 1 }).day
									"
								>
									{{ DateTime.fromISO(offer.expires_at).toFormat('t') }} tomorrow
								</template>

								<!-- today -->
								<template v-else>
									{{ DateTime.fromISO(offer.expires_at).toFormat('t') }} today
								</template>
							</div>
						</div>

						<div class="text-right" v-if="offer.offer_status == 'open'">
							<button
								v-if="authStore.authenticated && offer.created_by_user_id === authStore.user.id"
								@click.stop="handleOfferCancel(offer)"
								class="btn shadow-none text-gray-400 h-full hover:text-slate-800 hover:bg-gray-200 hover:shadow space-x-2 whitespace-nowrap group"
							>
								<span><i class="fas fa-trash group-hover:text-red-600"></i></span>
								<span>Cancel</span>
							</button>
						</div>
						<div
							v-else-if="offer.offer_status == 'cancelled' || offer.offer_status == 'expired'"
							class="text-center md:ml-16 ml-64 text-red-600 capitalize text-sm"
						>
							{{ offer.offer_status }}
						</div>
						<div
							v-else-if="offer.offer_status == 'fulfilled'"
							class="text-center md:ml-16 ml-64 text-green-600 capitalize text-sm"
						>
							{{ offer.offer_status }}
						</div>
					</article>
				</div>
			</div>
		</section>
		<cancel-offer-modal
			v-if="state.isCancelling"
			:offer="(state.offer as Offer)"
			:asset="state.offer?.asset"
			@close="handleModal(false)"
			@reload="handleModal(true)"
		/>
	</account-layout-with-nav>
</template>
<script lang="ts" setup>
import { Offer, Storefront } from '@/types/Storefront'
import { ComputedRef, computed, onBeforeMount, onMounted, onUnmounted, reactive, ref } from 'vue'
import { useAccountStore } from '@/stores/AccountStore'
import { useMarketStore } from '@/stores/MarketStore'
import AccountOffersFilters from '@/pages/Account/AccountOffersFilter.vue'
import { useStorefrontStore } from '@/stores/StorefrontStore'
import filterSummary from '@/components/FilterSummary.vue'
import storefrontFilterItem from '@/components/StorefrontFilterItem.vue'
import AccountLayoutWithNav from '@/layouts/AccountLayoutWithNav.vue'
import { humanReadablePrice } from '@/util/currencyFormat'
import CancelOfferModal from '@/components/modals/CancelOfferModal.vue'
import { useAuthStore } from '@/stores/AuthStore'
import { Store } from 'pinia'
import { useRouter, RouteLocationRaw } from 'vue-router'
import { DateTime } from 'ts-luxon'

const emit = defineEmits<{
	(e: 'reload-asset'): void
}>()

const router = useRouter()
const authStore = useAuthStore()
const accountStore = useAccountStore()
const marketStore = useMarketStore()
const storeStore = useStorefrontStore()

const state = reactive<{
	isLoading: boolean
	offer: Offer | null
	offers: Offer[]
	filteredStorefrontIDs: string[]
	selectedStatuses: string[]
	isFilterOpen: boolean
	isCancelling: boolean
}>({
	isLoading: true,
	offer: null,
	offers: [],
	filteredStorefrontIDs: [],
	selectedStatuses: ['Active'],
	isFilterOpen: false,
	isCancelling: false,
})

const offers: ComputedRef<Offer[]> = computed(() => {
	if (state.isLoading) return []
	return filteredOffers.value
})

onBeforeMount(async () => {
	await loadOffers()
	handleResize()
})

onMounted(async () => {
	window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
	window.removeEventListener('resize', handleResize)
})

onMounted(() => {})

async function loadOffers(isForceLoad: boolean = false) {
	state.isLoading = true
	await getOffersOwnedByUser(isForceLoad)
	state.isLoading = false
}

async function getOffersOwnedByUser(isForceLoad: boolean = false) {
	await accountStore.loadOffers(isForceLoad)
	state.offers = accountStore.offers
}

function getStorefront(storeId: string): Storefront {
	return marketStore.byID[storeId] ?? ''
}

const offerStorefronts: ComputedRef<Storefront[]> = computed(() => {
	if (state.isLoading) return []

	const storefronts = [
		...marketStore.storeFronts.filter(function (sf) {
			for (const offer of accountStore.offers) {
				if (offer.storefront_id == sf.id) {
					return true
				}
			}

			return false
		}),
	]

	return storefronts
})

const filteredStorefronts: ComputedRef<Storefront[]> = computed(() => {
	if (state.isLoading) return []

	return offerStorefronts.value.filter((storefront) => {
		return state.filteredStorefrontIDs.indexOf(storefront.id) >= 0
	})
})

const statusMapping = {
	Active: 'open',
	Expired: 'expired',
	Filled: 'fulfilled',
	Cancelled: 'cancelled',
	Terminated: 'cancelled',
}

function updateStatus({ status, checked }) {
	if (!state.selectedStatuses.includes(status)) {
		state.selectedStatuses.push(status)
	} else {
		state.selectedStatuses = state.selectedStatuses.filter((s) => s !== status)
	}
}

const filteredOffers: ComputedRef<Offer[]> = computed(() => {
	if (state.isLoading) return []

	if (!state.selectedStatuses.length && !state.filteredStorefrontIDs.length) {
		return state.offers
	}

	const filtered = state.offers.filter((offer) => {
		const storefrontMatch =
			!state.filteredStorefrontIDs.length || state.filteredStorefrontIDs.includes(offer.storefront_id)
		const statusMatch =
			!state.selectedStatuses.length ||
			state.selectedStatuses.map((status) => statusMapping[status]).includes(offer.offer_status)

		return storefrontMatch && statusMatch
	})

	return filtered
})
function toggleStorefront(storefront: Storefront) {
	let currentIndex = state.filteredStorefrontIDs.indexOf(storefront.id)
	if (currentIndex >= 0) {
		state.filteredStorefrontIDs.splice(currentIndex, 1)
	} else {
		state.filteredStorefrontIDs.push(storefront.id)
	}

	if (state.filteredStorefrontIDs.length == 1) {
		storeStore.loadStore(state.filteredStorefrontIDs[0])
	}
}

function toggleFilters() {
	state.isFilterOpen = !state.isFilterOpen
}

function handleToggleStatus(status: string) {
	updateStatus(status)
}

function handleToggleStorefront(storefront: Storefront) {
	toggleStorefront(storefront)
}

function clearAllFilters() {
	state.filteredStorefrontIDs = []
	state.selectedStatuses = []
	state.isFilterOpen = false
}

function humanReadableToBpx(humanValue: string): bigint {
	const parts = humanValue.split('.')
	const integralPart = BigInt(parts[0]) * BigInt(1e9)
	if (parts.length === 1) {
		return integralPart
	}
	// 9 decimal precision, pad with zeros to 9 digits (1e9)
	const truncated = parts[1].slice(0, 9).padEnd(9, '0')
	const decimalPart = BigInt(truncated)
	return integralPart + decimalPart
}

// Convert Bipixies (no decimals) to BPX (9 decimal places)
function toFloat(value: bigint): number {
	const integralPart = value / BigInt(1e9)
	const decimalPart = Number(value % BigInt(1e9)) / 1e9
	return Number(integralPart) + decimalPart
}

function handleOfferClick(offer: Offer) {
	const route: RouteLocationRaw = { params: { slug: getStorefront(offer.storefront_id).slug } }
	if (offer.asset !== null) {
		route.name = 'asset'
		route.params.assetId = offer.asset.id
	} else if (offer.offer_for_trait_prop !== null && offer.offer_for_trait_val !== null) {
		route.name = 'storefront'
		const query = {}
		query[offer.offer_for_trait_prop] = offer.offer_for_trait_val
		route.query = query
	} else {
		// any trait, so we clear out the filters before going to storefront
		// in case the user has filters applied from previous browsing/filtering
		storeStore.clearFilters()
		route.name = 'storefront'
	}

	router.push(route)
}

function handleOfferCancel(offer: Offer) {
	state.offer = offer
	state.isCancelling = true
}
async function handleModal(shouldReload: boolean) {
	if (state.isCancelling) {
		state.isCancelling = false
	}

	state.offer = null

	if (shouldReload) {
		await loadOffers(true)
	}
}

const handleResize = () => {
	state.isFilterOpen = window.innerWidth >= 1024
}
</script>
