<template>
	<router-link custom v-slot="{ navigate }" :to="props.url">
		<button type="button" @click="navigate" class="cursor-pointer transition-all hover:scale-105">
			<div class="relative">
				<img
					:src="$cdnify(props.img, 512)"
					class="rounded-xl overflow-hidden shadow-lg shadow-gray-600/50"
					:title="`${props.asset} - ${props.collection}`"
				/>
			</div>
			<div class="mt-4" v-if="props.bpx_price != 0">
				<div class="font-bold text-xl">
					{{ $format(humanReadablePrice(props.bpx_price)) }}
					{{ $token }}
				</div>
			</div>
		</button>
	</router-link>
</template>
<script lang="ts" setup>
import ToUsd from './ToUsd.vue'
import { humanReadablePrice } from '@/util/currencyFormat'

const props = defineProps({
	asset: { required: true, type: String },
	collection: { required: true, type: String },
	img: {
		required: true,
		type: String,
	},
	bpx_price: {
		required: true,
		type: [String, Number],
	},
	url: {
		required: true,
		type: String,
	},
})
</script>
