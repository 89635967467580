<template>
	<modal ref="purchaseModal">
		<template #default="handlers">
			<buy-store-credit-modal
				v-if="state.buyStoreCredit"
				:redirect="listingPermalink"
				@close="state.buyStoreCredit = false"
			/>
			<div class="bg-white rounded-xl overflow-hidden drop-shadow-md w-full">
				<div class="p-4 pb-6 md:flex md:space-x-6">
					<div class="hidden md:block w-1/3 flex-grow-0 flex-shrink-0">
						<div class="font-poppins text-ellipsis text-center">
							<p :title="props.asset.name" class="overflow-hidden text-ellipsis">
								{{ props.asset.name }}
							</p>
							<img :src="props.asset.image" class="rounded-md h-48 sm:h-auto mx-auto" />
						</div>
					</div>
					<div class="flex-grow flex flex-col">
						<div v-if="state.step == 1 || props.listing.listing_type == LISTING_TYPE_FIXED">
							<p class="text-sm text-gray-500">
								<span class="text-sm text-gray-500">Quantity: </span>
								<span class="tracking-tighter">
									{{ $format(props.listing.quantity) }}
								</span>
							</p>

							<p class="text-sm text-gray-500 mt-1">
								<span v-if="props.listing.listing_type == LISTING_TYPE_AUCTION">Minimum bid</span>
								<span v-else>
									<span v-if="props.listing.quantity > 1">Total</span>
									Price
								</span>
							</p>
							<p class="text-3xl font-bold tracking-tighter">
								<span v-if="props.listing.listing_type == LISTING_TYPE_AUCTION">
									{{
										$format(
											humanReadablePrice(
												props.listing.high_bid_amount
													? props.listing.high_bid_amount + props.listing.bid_increment
													: props.listing.price
											)
										)
									}}
									{{ $token }}
								</span>
								<span v-else>
									{{
										$format(
											humanReadablePrice(String(props.listing.price * props.listing.quantity))
										)
									}}
									{{ $token }}
								</span>
							</p>
							<p
								v-if="props.listing.listing_type == LISTING_TYPE_AUCTION"
								class="text-sm text-gray-500 mt-2 hidden"
							>
								Bid in 100 {{ $token }} increments
							</p>
						</div>

						<p v-if="state.step == 1 && state.error" class="text-red-600 font-medium my-2">
							{{ state.error }}
						</p>

						<div v-if="state.step == 1 && props.listing.listing_type == LISTING_TYPE_AUCTION" class="my-3">
							<div class="flex justify-between">
								<label class="font-lg font-bold block" for="max-bid">Your maximum bid:</label>
								<p class="text-sm text-slate-400 text-right">Bid in 100 {{ $token }} increments</p>
							</div>
							<p v-if="errors && errors.bid" class="text-red-600 text-sm font-medium">
								{{ errors.bid }}
							</p>
							<div
								class="mt-1 flex bg-white items-stretch border border-gray-200 rounded-xl overflow-hidden shadow focus-within:ring-1 ring-blue-800 ring-offset-2"
							>
								<input
									type="text"
									placeholder="Enter your maximum bid"
									maxlength="10"
									class="w-full rounded border-none text-lg font-medium py-2 px-4 outline-none focus:outline-none focus:ring-0 ring-0"
									v-model="state.currentBid"
									@keyup="handlePaymentChange"
								/>
								<div
									class="bg-gray-100 text-gray-400 flex-grow-0 py-2 px-4 flex justify-center items-center"
								>
									{{ $token }}
								</div>
							</div>
						</div>

						<!-- class="text-sm sm:text-base grid grid-cols-[3fr,4fr] border border-b-0 border-gray-200 rounded-t-xl px-4 py-2" -->
						<div v-if="props.listing.listing_type == LISTING_TYPE_AUCTION" class="col-span-2 px-4 mt-2">
							<p class="text-right text-sm text-gray-500 leading-none">Your maximum bid</p>
							<p
								class="text-right font-bold"
								:class="[state.step == 1 ? 'text-lg' : 'text-3xl tracking-tighter my-2']"
							>
								{{ $format(String(state.currentBid)) }}
								{{ $token }}
							</p>
						</div>

						<div
							v-if="
								parseInt(state.currentBid) > 0 && state.paymentBreakdown.outstanding != state.currentBid
							"
							class="pb-3 mt-2"
						>
							<h2 class="font-medium text-gray-700 border-b-2 border-gray-200">Payment Summary</h2>

							<template v-for="c in state.paymentBreakdown.payments">
								<div
									v-if="c.usd_amount > 0"
									:key="`listing-currency-${c.currency.id()}`"
									class="text-sm sm:text-base grid grid-cols-[3fr,2fr] gap-y-2 mt-1"
								>
									<p class="text-right font-bold">{{ c.currency.name() }}</p>
									<p class="text-right px-4">
										{{ c.currency.format(c.currency.isUSD() ? c.usd_amount : c.bpx_amount) }}
									</p>
								</div>
							</template>

							<div
								v-if="
									props.listing.listing_type == LISTING_TYPE_AUCTION &&
									state.step == 1 &&
									availableAuctionCredit > 0
								"
								class="text-sm text-right text-gray-500 mt-4 px-4 flex items-center space-x-1 justify-end"
							>
								<span
									class="cursor-pointer inline-flex w-5 h-5 bg-gray-100 border border-gray-200 rounded items-center justify-center aspect-square text-xs"
									@click="toggleAuctionCredit"
								>
									<span
										@click="toggleAuctionCredit"
										class="text-blue-500"
										:class="[!state.useAuctionCredit ? 'hidden' : '']"
										><i class="fa fa-check"></i
									></span>
								</span>
								<p class="cursor-pointer" @click="toggleAuctionCredit">
									Apply Auction Credit: ${{ $format(availableAuctionCredit / 100) }} available
								</p>
							</div>
						</div>

						<div v-if="props.listing.listing_type == LISTING_TYPE_AUCTION">
							<p class="text-sm text-slate-500 px-2 my-1">
								<strong>Note:</strong> The entirety of your maximum bid will be held until you are
								outbid. If you are the high-bidder at the end of the auction the balance of your maximum
								bid, less the winning bid amount, will be returned to you.
							</p>
						</div>

						<div
							v-if="state.paymentBreakdown.outstanding > 0"
							class="text-center px-4 bg-red-100 py-2 rounded-xl mt-4"
						>
							<p class="text-center font-bold pt-2 leading-none">Additional {{ $token }} Required</p>
							<p class="text-center font-bold text-2xl pt-2 leading-none">
								{{ currencyFormat(humanReadablePrice(state.paymentBreakdown.outstanding)) }}
								{{ $token }}
							</p>
						</div>

						<div class="col-span-2 sm:col-span-1">
							<div v-if="state.step > 1" class="flex-grow h-full mx-2 my-4">
								<div>
									<p class="text-red-600">
										<span class="text-lg">
											<i class="fa-sharp fa-solid fa-circle-exclamation"></i>
										</span>
										<span
											v-if="props.listing.listing_type == LISTING_TYPE_AUCTION"
											class="font-medium text-sm"
											>&nbsp;Your bid is not complete!
										</span>
										<span v-else class="font-medium text-sm"
											>&nbsp;Your purchase is not complete!
										</span>
									</p>
									<p class="font-medium text-balance text-sm text-slate-900">
										<span v-if="props.listing.listing_type == LISTING_TYPE_AUCTION">
											Verify your bid details above, and click "Place Bid" below to complete your
											bid.
										</span>
										<span v-else>
											Verify your payment details above, and click "Complete Purchase" below to
											complete your purchase.
										</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="bg-gray-200 px-4 py-4 text-right space-x-4 border-t-2 border-gray-300">
					<button v-if="state.step == 1" class="text-gray-500" @click="handlers.close">Cancel</button>
					<button v-if="state.paymentBreakdown.outstanding > 0">
						<button class="btn-primary" @click="state.buyStoreCredit = true">
							Add
							<span v-if="availableBpx > 0">More </span>
							Reward Miles
						</button>
					</button>
					<button
						v-if="state.step == 1 && state.paymentBreakdown.outstanding == 0"
						class="btn-primary"
						:disabled="!bidValid"
						@click="state.step += 1"
					>
						Next
					</button>

					<button
						v-if="state.step > 1"
						class="text-gray-500"
						:disabled="state.processingPurchase"
						@click="state.step -= 1"
					>
						Back
					</button>
					<button
						v-if="state.step > 1"
						class="btn-primary"
						:disabled="state.processingPurchase"
						@click="completePurchase"
					>
						<template v-if="props.listing.listing_type == LISTING_TYPE_FIXED">
							<span v-if="!state.processingPurchase">Complete Purchase</span>
							<span v-else-if="state.processingPurchase">
								Processing
								<i class="far fa-spinner-third fa-spin"></i>
							</span>
						</template>
						<template v-else-if="props.listing.listing_type == LISTING_TYPE_AUCTION">
							<span v-if="!state.processingPurchase">Place Bid</span>
							<span v-else-if="state.processingPurchase">
								Bidding
								<i class="far fa-spinner-third fa-spin"></i>
							</span>
						</template>
					</button>
				</div>
			</div>
		</template>
	</modal>
</template>
<script lang="ts" setup>
import { computed, onMounted, reactive, ref } from 'vue'
import { computedAsync } from '@vueuse/core'
import ToUsd from '../ToUsd.vue'
import { Asset, Listing } from '@/types/Asset'
import { Storefront } from '@/types/Storefront'
import Currency from '@/types/Currency'
import { humanReadablePrice } from '@/util/currencyFormat'
import api from '@/util/api'
import { useAuthStore } from '@/stores/AuthStore'
import { useNewWalletStore } from '@/stores/NewWalletStore'
import BN from 'bn.js'
import StorefrontChoosePaymentMethod from '@/components/StorefrontChoosePaymentMethod.vue'
import StorefrontConfirmBpxPayment from '@/components/StorefrontConfirmBpxPayment.vue'
import BuyStoreCreditModal from '@/components/modals/BuyStoreCreditModal.vue'
import PaymentDetails from '@/components/widgets/PaymentDetails.vue'
import { useMarketStore, paymentMethods } from '@/stores/MarketStore'
import sleep from '@/util/sleep'
import { useRoute, useRouter } from 'vue-router'
import { useExchangeStore } from '@/stores/ExchangeStore'
import currencyFormat from '@/util/currencyFormat'
import { usePaymentModule, allocateBpxPayment, PaymentAmounts } from '@/modules/PaymentModule'

const authStore = useAuthStore()
const newWalletStore = useNewWalletStore()
const router = useRouter()
const route = useRoute()
const { paymentCurrencies } = usePaymentModule()
const marketStore = useMarketStore()

const LISTING_TYPE_FIXED = 'fixed'
const LISTING_TYPE_AUCTION = 'auction'

const props = defineProps<{
	asset: Asset
	listing: Listing
}>()

const purchaseModal = ref(null)

const emit = defineEmits<{
	(event: 'bidSuccess', bidResponse: any): void
}>()

const state = reactive({
	step: 1,
	error: null as null | string,
	paymentMethod: null as null | String,
	processingPurchase: false,
	buyStoreCredit: false,
	minimumBid: Math.round(
		Math.max(props.listing.price, props.listing.high_bid_amount + props.listing.bid_increment) / 1e9
	),
	currentBid: Math.round(
		Math.max(
			props.listing.price * props.listing.quantity,
			props.listing.high_bid_amount + props.listing.bid_increment,
			(props.listing.viewer_max_bid?.bid_amount ?? 0) + props.listing.bid_increment
		) / 1e9
	),
	useAuctionCredit: false,
	paymentBreakdown: {} as {
		outstanding: number
		payments: PaymentAmounts
	},
})

onMounted(async () => {
	// console.log('computing payment breakdown', state.currentBid)
	state.paymentBreakdown = await allocateBpxPayment(listingCurrencies.value, state.currentBid, true)
})

const paymentPriority = [
	// {
	// 	currency: paymentCurrencies[newWalletStore.CURRENCY_PENDING_AUCTION_CREDIT],
	// 	methods: [{ method: 'auction', order: 0 }],
	// },
	{
		currency: paymentCurrencies[newWalletStore.CURRENCY_USABLE_BPX],
		methods: [
			{ method: 'auction', order: 1 },
			{ method: 'fixed', order: 0 },
		],
	},
	// {
	// 	currency: paymentCurrencies[newWalletStore.CURRENCY_STORE_CREDIT],
	// 	methods: [
	// 		{ method: 'auction', order: 2 },
	// 		{ method: 'fixed', order: 1 },
	// 	],
	// },
]

function toggleAuctionCredit() {
	state.useAuctionCredit = !state.useAuctionCredit
	updatePayments()
}

const listingType = computed(() => {
	return props.listing.listing_type
})

const listingCurrencies = computed(() => {
	return paymentPriority
		.filter((c) => c.methods.find((m) => m.method == listingType.value))
		.filter((c) => state.useAuctionCredit || c.currency.id() != newWalletStore.CURRENCY_PENDING_AUCTION_CREDIT)
		.map((c) => c.currency)
})

async function processPurchase(callback) {
	state.error = false
	state.processingPurchase = true
	return callback()
		.then(() => {
			state.processingPurchase = false
		})
		.catch(() => {
			state.processingPurchase = false
		})
}

function minSleep(duration: number, callback) {
	return async () => {
		const wait = sleep(duration)
		callback()
		return await wait
	}
}

function completePurchase(event) {
	if (props.listing.listing_type == LISTING_TYPE_AUCTION) {
		placeBid()
		return
	}
	processPayment()
	return
}

async function placeBid() {
	await processPurchase(
		minSleep(1.5, async () => {
			const result = await marketStore.placeBid(
				listingCurrencies.value.map((c) => c.id()),
				Currency.fromDecimal(state.currentBid).toString(),
				props.listing.id
			)

			if (result.success) {
				newWalletStore.pollBalance()
				purchaseModal.value.close()
				emit('bidSuccess', result)
			} else {
				state.step -= 1
				state.error = result.message ?? 'An unknown error occurred while placing your bid.'
			}
		})
	)
}

async function processPayment() {
	state.processingPurchase = true
	const marketStore = useMarketStore()
	const s = sleep(1.5)
	const result = await marketStore.buyListing(
		listingCurrencies.value.map((c) => c.id()),
		props.listing.id
	)
	await s

	if (result.success) {
		newWalletStore.pollBalance()

		// token has been purchased. sweet.
		// now we have to notify the user, and update the user interface
		// (close modal, remove listing, show how many the user holds, etc)
		// probably best to just reload the whole page.
		router.replace({
			name: 'asset',
			params: {
				slug: route.params.slug,
				assetId: route.params.assetId,
			},
			query: {
				success: 'Purchase completed successfully!',
			},
		})
	} else {
		alert(result.message)
	}

	state.processingPurchase = false
}

const listingPermalink = computed(() => {
	return router.resolve({
		name: 'purchase-listing',
		params: {
			slug: marketStore.byID[props.asset.storefront_id].slug,
			assetId: props.asset.id,
			listingId: props.listing.id,
		},
	}).href
})

const errors = computed(() => {
	let errs = {} as { [k: string]: string }
	if (state.currentBid < state.minimumBid) {
		errs.bid = 'Bid too low'
		return errs
	}

	if (Number.isNaN(state.currentBid)) {
		errs.bid = `Bids must be numbers only.`
		return errs
	}
})

function handlePaymentChange(evt) {
	updatePayments()
}

async function updatePayments() {
	state.paymentBreakdown = await allocateBpxPayment(listingCurrencies.value, state.currentBid, true)
}

const bidValid = computed(() => {
	return listingType.value != 'auction' || state.currentBid >= state.minimumBid
})

const availableAuctionCredit = computed(() => {
	return newWalletStore.balances.find((b) => b.slug == 'usd-auction-credit')?.available ?? null
})

const availableBpx = computed(() => {
	return newWalletStore.balancesByType['bpx']?.available ?? 0
})
</script>
