<template>
	<section ref="accountDrips">
		<div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 md:auto-rows-[25rem]">
			<drip-summary-tile v-if="!loading" :totals="totals" @claim="() => (showClaimModal = true)" />

			<drip-claim-history
				v-if="!loading"
				class="order-last md:order-none lg:col-span-2 rounded-xl shadow"
				:history="data.history"
			/>

			<drip-summary-tile
				v-for="(storefrontTotals, storefrontID) in totalsByStorefront"
				:key="`drips-${storefrontID}`"
				:totals="storefrontTotals"
			>
				<template #title>
					<div class="flex justify-start">
						<h1 class="text-3xl tracking-tighter mb-3 flex-grow pr-4">
							{{ marketStore.byID[storefrontID].name }}
						</h1>
						<div class="w-16 flex-grow-0 flex-shrink-0">
							<img
								:src="marketStore.byID[storefrontID].pfp_image"
								class="w-full rounded-lg overflow-hidden shadow"
							/>
						</div>
					</div>
					<div class="flex-grow"></div>
				</template>
				<template #footer><span></span></template>
			</drip-summary-tile>

			<claim-all-modal
				v-if="!loading && showClaimModal"
				:totals="totals"
				@close="() => (showClaimModal = false)"
				@claimed="loadData(true)"
				@error="loadData(true)"
			/>
		</div>
	</section>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import DripSummaryTile from '@/components/Drips/DripSummaryTile.vue'
import DripClaimHistory from '@/components/Drips/DripClaimHistory.vue'
import ClaimAllModal from '@/components/Drips/ClaimAllModal.vue'
import { AccountDripIndexResponse, Drip } from '@/types/Drip'
import { useMarketStore } from '@/stores/MarketStore'
import sleep from '@/util/sleep'
import Bugsnag from '@bugsnag/js'

const marketStore = useMarketStore()

const loading = ref<Boolean>(true)
const data = ref<AccountDripIndexResponse | null>(null)
const showClaimModal = ref<boolean>(false)

const props = defineProps<{
	dataLoader: () => Promise<AccountDripIndexResponse>
}>()

onMounted(async () => {
	await loadData()
})

const loadData = async (silently: boolean = false) => {
	const wait = sleep(0.75)
	if (!silently) {
		loading.value = true
	}

	data.value = await props.dataLoader()

	if (!silently) {
		await wait
		loading.value = false
	}

	return data.value
}

const totals = computed(() => {
	if (loading.value) {
		return {}
	}

	return data.value
})

const totalsByStorefront = computed(() => {
	if (loading.value) {
		return {}
	}

	return data.value.drips.reduce((agg, drip) => {
		if (!drip.asset) {
			Bugsnag.notify(new Error('Drip missing asset'), function (evt) {
				evt.severity = 'error'
				evt.addMetadata('drip', {
					...drip,
				})
			})

			return agg
		}

		const storefront = drip.asset.storefront_id

		if (!agg[storefront]) {
			agg[storefront] = {
				daily_emission: 0,
				remaining_emission: 0,
				total_emission: 0,
				current_value: 0,
				withdrawn_value: 0,
				claimable_count: 0,
				drips: [] as Drip[],
			} as AccountDripIndexResponse
		}

		agg[storefront].daily_emission += drip.daily_emission
		agg[storefront].remaining_emission += drip.remaining_emission
		agg[storefront].total_emission += drip.total_emission
		agg[storefront].current_value += drip.claimable ? drip.current_value : 0
		agg[storefront].withdrawn_value += drip.withdrawn_value
		agg[storefront].claimable_count += drip.claimable
		agg[storefront].drips.push(drip)

		return agg
	}, {})
})
</script>
