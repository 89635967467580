<template>
	<account-layout-with-nav>
		<div class="relative my-6 px-4 mt-4">
			<div class="my-4 flex justify-between">
				<paginator
					:pages="pagedAssets.pages"
					:current="pagedAssets.currentPage + 1"
					:show-jump-buttons="false"
					@select-page="selectPage"
				/>

				<div v-if="pagedAssets.pages" class="text-right text-gray-400 text-sm self-start">
					Viewing page {{ pagedAssets.currentPage + 1 }} of {{ pagedAssets.pages }}
				</div>
			</div>
			<div class="mt-2">
				<tile-loader
					v-if="state.loading"
					:count="36"
					class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4"
				>
					<loading-tile />
				</tile-loader>
				<div v-else>
					<div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
						<storefront-asset
							v-for="asset in pagedAssets.assets"
							:key="`asset-${asset.id}`"
							:asset="asset"
							:slug="getStorefrontSlug(asset.storefront_id)"
							:show-storefront-name="true"
							:image-width="512"
							@favorite="handleFavorite"
						/>
					</div>
					<div class="mt-4 flex justify-between">
						<paginator
							:pages="pagedAssets.pages"
							:current="pagedAssets.currentPage + 1"
							:show-jump-buttons="false"
							@select-page="selectPage"
						/>

						<div v-if="pagedAssets.pages" class="text-right text-gray-400 text-sm self-start">
							Viewing page {{ pagedAssets.currentPage + 1 }} of {{ pagedAssets.pages }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</account-layout-with-nav>
</template>
<script lang="ts" setup>
import { computed, defineComponent, onBeforeMount, onMounted, onUnmounted, PropType, reactive, ref } from 'vue'
// components
import AccountLayoutWithNav from '@/layouts/AccountLayoutWithNav.vue'
import { useAuthStore } from '@/stores/AuthStore'
import { useRoute } from 'vue-router'
import Paginator from '@/components/widgets/Paginator.vue'
import api from '@/util/api'
import StorefrontAsset from '@/components/StorefrontAsset.vue'
import LoadingTile from '@/components/LoadingTile.vue'
import TileLoader from '@/components/TileLoader.vue'
import { useMarketStore } from '@/stores/MarketStore'
import { Asset } from '@/types/Asset'
import { Storefront } from '@/types/Storefront'
const marketStore = useMarketStore()

const authStore = useAuthStore()
const favoritedAssets = ref([])

const state = ref({
	loading: true,

	//pagination
	pageSize: 102,
	currentPage: 0,
})

onBeforeMount(async () => {
	state.value.loading = true
	await getFavoritedAssets()
	state.value.loading = false
})

async function getFavoritedAssets() {
	await marketStore.load(true)
	const favAssets = await api.getUserFavoritedAssets()

	const favAssetsWithStorefrontData = favAssets.map((asset: Asset) => {
		asset.storefront = marketStore.storeFronts.find((sf) => sf.id === asset.storefront_id)
		return asset
	})

	favoritedAssets.value = favAssetsWithStorefrontData
}

const pagedAssets = computed(() => {
	const page = {
		pages: Math.ceil(favoritedAssets.value.length / state.value.pageSize),
		currentPage: state.value.currentPage,
		totalItems: favoritedAssets.value.length,
		assets: favoritedAssets.value.slice(
			state.value.currentPage * state.value.pageSize,
			state.value.currentPage * state.value.pageSize + state.value.pageSize
		),
	}

	return page
})

function selectPage(pg: number) {
	state.value.currentPage = pg - 1
}

function getStorefrontSlug(storeId: string) {
	return marketStore.byID[storeId]?.slug ?? ''
}

async function handleFavorite(isFavorite: boolean) {
	if (isFavorite === false) {
		await getFavoritedAssets()
	}
}
</script>
