<template>
	<header class="bg-black text-white relative z-50" id="site-header">
		<div
			class="flex lg:space-x-8 justify-between items-stretch px-2 lg:px-4 py-3 lg:py-4 drop-shadow-md font-poppins"
		>
			<div class="flex mr-4">
				<button
					class="justify-start px-2 mr-2 lg:hidden focus:outline-none focus:shadow-outline"
					ref="navToggleRef"
					@click="toggleMobileNav()"
				>
					<svg fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6">
						<path
							v-if="!isMobileMenuOpen"
							fill-rule="evenodd"
							d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
							clip-rule="evenodd"
						></path>
						<path
							v-else
							fill-rule="evenodd"
							d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
							clip-rule="evenodd"
						></path>
					</svg>
				</button>

				<router-link
					@click="toggleMobileNav(false)"
					:to="{ name: 'home' }"
					title="Blokpax Market"
					class="lg:ml-0 overflow-hidden flex items-center justify-center flex-shrink-0"
				>
					<!-- <LogoSvg width="64" height="64" fill="#ffffff" class="max-h-11 max-w-11 lg:hidden" /> -->
					<WordmarkSvg fill="#ffffff" class="h-64 max-h-8 sm:max-h-12 max-w-[18rem] lg:block" />
					<!-- <img src="/image/blokpax-logo-white.webp" class="hidden h-8"> -->
				</router-link>
			</div>

			<div class="flex flex-nowrap min-[681px]:grow">
				<div
					@click="toggleMobileNav(false)"
					class="order-1 min-[681px]:grow lg:basis-full mr-2 md:mx-4 h-auto flex overflow-hidden min-[681px]:mx-4 rounded-lg"
				>
					<div class="relative items-center grow flex" id="autocomplete">
						<!-- algolia autocomplete will render here -->
					</div>
				</div>

				<nav
					ref="navRef"
					class="bpmp-nav"
					:class="isMobileMenuOpen ? 'visible opacity-100' : 'invisible opacity-0'"
				>
					<ul class="lg:flex lg:items-stretch w-full flex-grow basis-full">
						<li
							v-if="!authStore.authenticated"
							class="group flex justify-center items-center pb-4 border-b-2 border-gray-200 lg:hidden"
						>
							<button class="btn-primary-lg" @click="handleLoginClicked">Login</button>
						</li>
						<li class="group lg:flex lg:justify-center lg:items-center featured-collection-fix relative">
							<div class="font-semibold text-lg whitespace-nowrap">Featured Collections</div>
							<ul class="bpmp-nav__children">
								<li
									v-for="storefront in featuredCollections"
									:key="storefront.name"
									class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-700 lg:items-center"
								>
									<div class="mt-1 flex h-11 w-11 shrink-0">
										<img :src="storefront.pfp_image" :alt="storefront.name" class="rounded-lg" />
									</div>
									<div>
										<router-link
											@click="isMobileMenuOpen = false"
											:to="{ name: 'storefront', params: { slug: storefront.slug } }"
											class="font-semibold"
										>
											{{ storefront.name }}
											<span class="absolute inset-0"></span>
										</router-link>
										<p class="mt-1 text-sm lg:hidden">{{ storefront.details.description }}</p>
									</div>
								</li>
							</ul>
						</li>
					</ul>
				</nav>
				<auth-block @click="toggleMobileNav(false)" class="order-last shrink-0" />
			</div>
		</div>
	</header>
</template>
<script lang="ts" setup>
import { h, onMounted, ref, Fragment, render, computed } from 'vue'
import AuthBlock from '@/components/AuthBlock.vue'
import LogoSvg from '@/../public/image/logo-noword.min.svg'
import WordmarkSvg from '@/../public/image/logo-wordmark.v2.min.svg'
import { useMarketStore } from '@/stores/MarketStore'
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches/dist/esm'
import algoliasearch from 'algoliasearch/lite'
import '@algolia/autocomplete-theme-classic'
import router from '@/router'
import { useAuthStore } from '@/stores/AuthStore'
import { useRoute } from 'vue-router'
import { onClickOutside } from '@vueuse/core'

const navRef = ref(null)
const navToggleRef = ref(null)
onClickOutside(
	navRef,
	function () {
		toggleMobileNav(false)
	},
	{ ignore: [navToggleRef] }
)

const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
	key: 'RECENT_SEARCH',
	limit: 5,
})

const isMobileMenuOpen = ref(false)
const marketStore = useMarketStore()

const ALGOLIA_APP_ID = import.meta.env.VITE_ALGOLIA_APP_ID
const ALGOLIA_SEARCH_KEY = import.meta.env.VITE_ALGOLIA_SEARCH_KEY

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY)

const authStore = useAuthStore()
const route = useRoute()

async function handleLoginClicked() {
	authStore.redirectToLogin(route.path)
}

function toggleMobileNav(isOpen: boolean = null) {
	if (isOpen === null) {
		isMobileMenuOpen.value = !isMobileMenuOpen.value
	} else {
		isMobileMenuOpen.value = isOpen
	}
}

const featuredCollections = computed(() =>
	marketStore.storeFronts
		.filter((storefront) => storefront.navigation_storefront_sort_order !== null)
		.sort((a, b) => a.navigation_storefront_sort_order - b.navigation_storefront_sort_order)
)

onMounted(() => {
	marketStore.load()

	autocomplete({
		container: '#autocomplete',
		plugins: [recentSearchesPlugin],
		openOnFocus: true,
		placeholder: 'Search cards or collections',
		// @ts-ignore
		getSources() {
			return [
				{
					sourceId: 'collections',
					getItems({ query }) {
						return getAlgoliaResults({
							searchClient,
							queries: [
								{ indexName: 'storefront', query },
								{ indexName: 'asset_index', query },
							],
						})
					},
					onSelect({ item }) {
						const { permalink, token_id, storefront_type } = item
						const splitPermaLink = `${permalink}`.split('/')

						if (token_id) {
							// asset
							const assetId = splitPermaLink.pop()
							const slug = splitPermaLink.pop()
							router.push({ name: 'asset', params: { slug, assetId } })
						}

						if (storefront_type) {
							// collection
							const slug = splitPermaLink.pop()
							router.push({ name: 'storefront', params: { slug } })
						}
					},
					templates: {
						item({ item: rawItem, html }) {
							const item = rawItem as any
							return html`
								<article class="flex gap-x-4 py-2 truncate">
									<img
										class="h-12 w-12 flex-none rounded-full bg-gray-50"
										src="${item?.token_id ? item.image : item.pfp_image}"
										alt="${item.name}"
									/>
									<div class="min-w-0">
										<p class="text-sm truncate font-semibold text-gray-900">${item.name}</p>
										<p class="mt-1 truncate text-xs text-gray-500">
											${item.storefront_name ? item.storefront_name : item.description_md}
										</p>
									</div>
								</article>
							`
						},
					},
					renderer: { createElement: h, Fragment, render },
				},
			]
		},
	})
})
</script>

<style lang="css" scoped>
.bpmp-nav {
	@apply absolute
		bg-gray-900
		left-0
		w-full
		max-h-[calc(100vh-4.5rem)]
		top-[4.5rem]
		p-4
		grow
		overflow-auto
		!m-0
		lg:overflow-visible
		lg:w-auto
		lg:h-auto
		lg:left-auto
		lg:relative
		lg:top-auto
		lg:visible
		lg:opacity-100
		lg:bg-transparent
		lg:text-white
		lg:flex
		lg:p-0
		transition-all
		lg:mx-8
		lg:justify-self-start;
}

.bpmp-nav__children {
	@apply py-4
		rounded-bl-lg
		rounded-br-lg
		lg:p-4
		lg:group-hover:opacity-100
		lg:group-hover:visible
		lg:absolute
		lg:w-[max-content]
		lg:bg-gray-900
		lg:top-16
		lg:opacity-0
		lg:invisible
		lg:left-0
		lg:max-h-[calc(100vh-5rem)]
		lg:overflow-auto
		transition-all
		duration-200;
}
</style>

<style>
/* non-scoped css to override Algolia Autocomplete styles */
.aa-DetachedSearchButton {
	@apply h-12
		border-0
		cursor-pointer
		w-12
		p-0
		flex
		justify-center
		items-center
		rounded-lg
		bg-slate-100
		hover:bg-slate-400
		border-slate-300;
}
.aa-Autocomplete {
	@apply w-full;
}
.aa-Form {
	@apply border-0;
}
.aa-Input {
	@apply h-12 truncate;
}
.aa-Panel {
	@apply min-[768px]:max-[1180px]:!w-1/2
	fixed
	!top-14
	lg:!top-16
	z-50;
}
.aa-DetachedContainer .aa-Panel {
	@apply !top-auto;
}
.aa-DetachedSearchButtonIcon {
	cursor: pointer;
}
.aa-DetachedSearchButtonPlaceholder {
	display: none;
}
.aa-SubmitButton {
	@apply flex justify-center items-center w-full px-3;
}
.aa-SubmitIcon {
	fill: #000;
	cursor: pointer;
}
.aa-PanelLayout {
	@apply max-h-[calc(100vh-4.5rem)];
}

.featured-collection-fix:hover:before {
	/*
		this is a hack to fix the hover state on the featured collections hover state.
		there is a gap between the parent and the child element that causes the hover state to flicker.
		adding a pseudo element to fill the gap so the hover state is consistent.
	*/
	content: '';
	@apply lg:w-full
	lg:h-4
	lg:absolute
	lg:-bottom-4;
}
</style>
