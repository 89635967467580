<template>
	<teleport to="body">
		<div ref="modal-backdrop" class="fixed z-50 inset-0 overflow-y-auto bg-black bg-opacity-50">
			<div class="flex lg:items-start justify-end absolute top-16 lg:top-24 right-0 text-center w-full">
				<div
					class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl py-5 px-4 w-[calc(100%-1.25rem)] md:w-96 mr-2 lg:mr-4"
					role="dialog"
					ref="modal"
					aria-modal="true"
					aria-labelledby="modal-headline"
				>
					<div class="flex flex-col">
						<div class="absolute top-0 right-0 px-4 py-3">
							<button @click="newWalletStore.toggleBalanceModal(false)">
								<i class="fa fa-times"></i>
							</button>
						</div>
						<div>
							<deposit-modal-tabs
								v-if="web3WalletDetected"
								class="mb-4 w-full"
								:active="state.currentTab"
								@tab="(t) => (state.currentTab = t)"
							/>
							<h2 v-else class="font-bold text-2xl text-center md:text-left">Your Balance</h2>

							<deposit-modal-tab-current v-if="state.currentTab == 'current'" />
							<deposit-modal-tab-exchange v-if="state.currentTab == 'swap'" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>
<script>
import { ref, watch, computed, reactive, defineComponent } from 'vue'
import { useWalletStore } from '@/stores/WalletStore'
import DepositModalTabs from '@/components/DepositModalTabs.vue'
import DepositModalTabCurrent from '@/components/DepositModalTabCurrent.vue'
import DepositModalTabExchange from '@/components/DepositModalTabExchange.vue'

import { useNewWalletStore } from '@/stores/NewWalletStore'
import BN from 'bn.js'
import Bugsnag from '@bugsnag/js'

export default defineComponent({
	name: 'DepositModal',
	components: {
		DepositModalTabs,
		DepositModalTabCurrent,
		DepositModalTabExchange,
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		switchShow(value) {
			props.show = value
		},
		adjustInput(event) {
			this.reactiveWidth = event.target.value.length + 'ch'
		},
	},
	computed: {
		reactiveWidth() {
			return this.number + 'ch'
		},
	},
	setup(props) {
		const state = reactive({
			currentTab: 'current',
		})

		const newWalletStore = useNewWalletStore()

		const bpxBalance = computed(() => {
			return newWalletStore.balances.reduce(function (bnBalance, bal) {
				if (bal.slug == 'bpx' || bal.slug == 'vbpx') {
					return bnBalance.add(new BN(bal.available))
				}

				return bnBalance
			}, new BN('0'))
		})

		const pendingAuctionCredit = computed(() => {
			return newWalletStore.balances.find((b) => b.slug == 'usd-auction-credit') ?? null
		})

		const web3WalletDetected = computed(() => {
			return !!window.ethereum
		})

		return {
			newWalletStore,
			bpxBalance,
			pendingAuctionCredit,
			web3WalletDetected,
			state,
		}
	},
})
</script>
