<template>
	<modal>
		<template #default="{ cancel }">
			<div class="bg-white rounded-xl pt-4 md:pt-6 shadow-md w-full">
				<div class="px-4 md:px-6">
					<h1 class="text-2xl font-semibold">Claim Rewards</h1>
					<div class="flex justify-start items-top mt-2">
						<div class="text-center flex-shrink-0 order-1 sm:mr-4">
							<p class="text-sm sm:hidden">{{ props.asset.name }}</p>
							<div class="overflow-hidden rounded md:rounded-xl">
								<img :src="props.asset.image" class="w-20 sm:w-40" />
							</div>
						</div>
						<div class="order-0 sm:order-1 flex-grow">
							<p class="font-bold hidden sm:block">{{ props.asset.name }}</p>
							<div class="leading-none sm:mt-4">
								<p class="text-sm text-gray-600">Available to Claim</p>
								<p class="text-3xl tracking-tighter font-extrabold text-gray-900">
									{{ $format(currencyToDecimal(props.drip.current_value)) }}
									{{ $token }}
								</p>
							</div>

							<div
								class="mt-2 space-y-2 sm:space-y-0 sm:flex sm:items-center sm:justify-start sm:space-x-4 sm:pt-2"
							>
								<div class="inline-flex shrink-0 items-center gap-2">
									<div class="text-sky-700">
										<i class="fa-solid fa-trophy"></i>
									</div>

									<div class="mt-1.5 sm:mt-0">
										<div class="text-sm text-gray-500">Rewards Remaining</div>
										<div class="font-medium text-sm">
											<span
												>{{
													$format(
														currencyToDecimal(
															props.drip.remaining_emission - props.drip.current_value
														)
													)
												}}
												{{ $token }}
											</span>
											<span class="ml-1 inline-block group relative cursor-pointer">
												<i class="fa-sm fa-regular fa-info-circle text-gray-500"></i>
												<div
													class="hidden border border-gray-200 rounded p-2 text-xs text-gray-500 group-hover:block absolute top-3 left-3 bg-white w-48 sm:w-60 z-10"
												>
													Remaining {{ $token }} Rewards that will accrue during the remaining
													drip period, if the currently available rewards are claimed.
												</div>
											</span>
										</div>
									</div>
								</div>

								<div class="inline-flex shrink-0 items-center gap-2">
									<div class="text-sky-700">
										<i class="fa-regular fa-clock"></i>
									</div>

									<div class="mt-1.5 sm:mt-0">
										<div class="text-sm text-gray-500">Time Remaining</div>
										<div class="font-medium text-sm">
											{{
												Math.max(parseInt(props.drip.maturity_date.diffNow().toFormat('d')), 0)
											}}
											<pluralize
												:amount="
													Math.max(
														parseInt(props.drip.maturity_date.diffNow().toFormat('d')),
														0
													)
												"
												singular="day"
												plural="days"
											/>
											<span class="ml-1 inline-block group relative cursor-pointer">
												<i class="fa-sm fa-regular fa-info-circle text-gray-500"></i>
												<div
													class="hidden border border-gray-200 rounded p-2 text-xs text-gray-500 group-hover:block absolute top-3 left-3 bg-white w-48 sm:w-60 z-10"
												>
													Remaining number of days that {{ $token }} rewards will accrue on
													this drip.
												</div>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="bg-gray-100 flex justify-end items-center mt-4 p-4 md:px-6 rounded-b-xl space-x-4 md:space-x-6"
				>
					<button class="text-gray-600 disabled:text-gray-400" @click="cancel" :disabled="claiming">
						Cancel
					</button>
					<button
						class="btn-primary-lg"
						@click="handleClaim"
						:disabled="claiming || props.drip.current_value < 1"
					>
						<span v-if="!claiming">
							Claim
							<span class="hidden"
								>{{ $format(currencyToDecimal(props.drip.current_value * 10000)) }} {{ $token }}</span
							>
							Reward
						</span>
						<span v-else-if="claiming">
							Claiming
							<i class="ml-2 fa-sm fa-regular fa-spinner fa-spin"></i>
						</span>
					</button>
				</div>
			</div>
		</template>
	</modal>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import { Drip, DripClaimResponse } from '@/types/Drip'
import { Asset } from '@/types/Asset'
import { waitLoad } from '@/util/sleep'
import { currencyToDecimal } from '@/util/currencyFormat'
import useDrips from '@/modules/UseDrips'

const drips = useDrips()

const props = defineProps<{
	asset: Asset
	drip: Drip | null
}>()

const emit = defineEmits(['claimed'])

const claiming = ref<boolean>(false)

async function handleClaim() {
	claiming.value = true
	try {
		const status: DripClaimResponse = await waitLoad(
			async () => await drips.claim(props.drip.asset_id),
			() => (claiming.value = false),
			1
		)

		if (status.success) {
			emit('claimed')
		}
	} catch (e) {
		claiming.value = false
	}
}
</script>
