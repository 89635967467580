<template>
	<account-layout-with-nav>
		<Loader :loading="state.loading" class="relative my-6 px-4 mt-4">
			<div class="my-4 flex space-x-4 md:ml-36 flex-wrap-reverse">
				<paginator
					:pages="Math.ceil(state.pagination.total / state.pagination.per_page)"
					:current="state.currentPage"
					:show-jump-buttons="false"
					@select-page="selectPage"
				/>

				<div class="text-right text-gray-400 text-sm self-start">
					Viewing page {{ state.currentPage }} of
					{{ Math.ceil(state.pagination.total / state.pagination.per_page) }}
				</div>
			</div>
			<div class="md:flex flex-row flex-wrap justify-center">
				<div v-if="state.data.length === 0" class="p-4 text-center">
					<p class="text-xl mb-4 tracking-tighter">You do not currently have any active listings.</p>
					<p class="text-gray-500 tracking-tight">Check out your collection to create a new listing</p>
					<p class="my-6">
						<router-link class="btn-primary-lg" :to="{ name: 'account' }">Your Collection</router-link>
					</p>
				</div>
				<div
					v-else
					v-for="(listing, idx) in state.data"
					:key="`listing-${listing.id}`"
					class="grid grid-cols-[1fr,4fr] gap-y-2 gap-x-4 p-4 items-start md:shadow-md mx-auto md:m-4 md:max-w-lg md:rounded-lg md:bg-transparent"
					:class="[idx % 2 == 1 ? 'bg-gray-200/60' : 'bg-transparent']"
				>
					<router-link
						custom
						:to="{
							name: 'asset',
							params: {
								slug: storefrontSlug(listing.asset.storefront_id),
								assetId: listing.asset.id,
							},
						}"
						v-slot="{ navigate }"
					>
						<div class="col-span-2 grid grid-cols-[3fr,1fr] items-center">
							<p class="font-semibold cursor-pointer" @click="navigate">{{ listing.asset.name }}</p>
							<p class="text-sm text-right font-semibold text-slate-900 rounded py-2 whitespace-nowrap">
								<span v-if="listing.listing_type == 'fixed'">
									<i class="fa-light fa-store"></i>
									Buy Now
								</span>
								<span v-else>
									<i class="fa-light fa-gavel"></i>
									Auction
								</span>
							</p>
						</div>
						<div class="relative">
							<img :src="listing.asset.image" class="rounded-xl cursor-pointer" @click="navigate" />
						</div>
						<div class="">
							<div class="text-sm text-slate-700 grid grid-cols-[3fr,1fr] gap-4">
								<div>
									<p>
										<span class="font-semibold">
											{{ listing.listing_type == 'auction' ? 'Current Bid' : 'Price' }}:
										</span>
										{{ $format(humanReadablePrice(listingPrice(listing))) }}
										{{ $token }}
									</p>
									<p><span class="font-semibold">Quantity:</span> 1</p>
									<p>
										<template v-if="listing.listing_type == 'auction'">
											<span>
												<span class="font-semibold">Ends at:</span>
												{{
													DateTime.fromISO(listing.extended_bidding_ends_at).toFormat(
														'M/dd/yyyy \@ h:mm a ZZZZ'
													)
												}}
											</span>
										</template>
										<template v-else>
											<span class="font-semibold">Ends at:</span>
											{{
												DateTime.fromISO(listing.expires_at).toFormat(
													'M/dd/yyyy \@ h:mm a ZZZZ'
												)
											}}
										</template>
									</p>
								</div>
								<div class="text-right">
									<div v-if="listing.listing_type == 'auction'">
										<p>
											<span class="font-semibold">Activity</span>
											<br />
											{{ $format(listing.bids.length) }} Bids
										</p>
									</div>
									<p class="my-2">
										<button @click="navigate" role="link" class="btn-primary">View</button>
									</p>
								</div>
							</div>
						</div>
					</router-link>
				</div>
			</div>
			<div class="my-4 flex space-x-4 md:ml-36 flex-wrap-reverse">
				<paginator
					:pages="Math.ceil(state.pagination.total / state.pagination.per_page)"
					:current="state.currentPage"
					:show-jump-buttons="false"
					@select-page="selectPage"
				/>

				<div class="text-right text-gray-400 text-sm self-start">
					Viewing page {{ state.currentPage }} of
					{{ Math.ceil(state.pagination.total / state.pagination.per_page) }}
				</div>
			</div>
		</Loader>
	</account-layout-with-nav>
</template>
<script lang="ts" setup>
import { useAccountStore } from '@/stores/AccountStore'
import { useMarketStore } from '@/stores/MarketStore'
import sleep from '@/util/sleep'
import { onMounted, reactive } from 'vue'
import { DateTime } from 'ts-luxon'
import Paginator from '@/components/widgets/Paginator.vue'

import AccountLayoutWithNav from '@/layouts/AccountLayoutWithNav.vue'
import Loader from '@/components/widgets/Loader.vue'
import { humanReadablePrice } from '@/util/currencyFormat'
import { Listing } from '@/types/Asset'

const accountStore = useAccountStore()
const marketStore = useMarketStore()

const state = reactive({
	loading: true,
	currentPage: 1,
	data: [],
	pagination: {},
})

onMounted(async () => {
	const wait = sleep(0.75)
	marketStore.load()
	await load()
	await wait
	selectPage(1)
	state.loading = false
})

async function load(page = state.currentPage) {
	const { data, meta } = await accountStore.loadActiveAuctions(page ?? 1)
	state.data = data
	state.pagination = meta
}

function listingPrice(listing: Listing): string {
	if (listing.listing_type == 'auction') {
		return listing.high_bid_amount
	}

	return String(listing.quantity * listing.price)
}

function storefrontSlug(storefrontID: string): string {
	return marketStore.byID[storefrontID].slug ?? null
}

function selectPage(pg: number) {
	state.currentPage = pg
	state.loading = true
	load()
	state.loading = false
}
</script>
